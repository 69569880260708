import { Injectable } from '@angular/core';
import { ApplicationModel } from '@base/modules/rest/application/model/application.model';
import { MenuItemModel } from '@base/modules/rest/application/model/menu-item.model';
import { MenuModel } from '@base/modules/rest/application/model/menu.model';
import { OrganizationMasterDataModel } from '@base/modules/rest/organization/model/organization-master-data.model';
import { EmployeeModel } from '@base/modules/rest/user/model/employee.model';
import { CurrentUserContextResponseModel } from '@base/modules/rest/user/response/current-user-context-response.model';
import { hasPermission } from '@base/modules/security/utils/permissions.util';
import { AppState } from '@base/store';
import { CoreApplicationSelectors } from '@base/store/application';
import { CoreOrganizationSelectors } from '@base/store/organization';
import { CoreUserSelectors } from '@base/store/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, interval, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Location } from '@angular/common';
import { SystemRoles } from '../modules/rest/user/enum/system-roles.enum';
import { StrategijskaPoslovnaJedinicaModel } from '../modules/rest/mis4/S/strategijska-poslovna-jedinica.model';
import { OrganizacijaModel } from '../modules/rest/mis4/O/organizacija.model';
import { PoslovnaGodinaModel } from '../modules/rest/mis4/P/poslovna-godina.model';
import { OrganizacionaJedinicaModel } from '../modules/rest/mis4/O/organizaciona-jedinica.model';
import { ParametersModel } from '../modules/rest/parameters/model/parameters.model';
import { ParametersRestService } from '../modules/rest/parameters/parameters-rest.service';
import { ObjectRestServiceCreator } from '../modules/rest/object/object-rest-service-creator';
import { ObjectNameEnum } from '../modules/rest/master-data-history/model/enums/object-name.enum';
import { ObjectActionRequestModel } from '../modules/rest/object/model/object-action-request.model';
import { UserResponseModel } from '../modules/rest/user/response/user-response.model';
import { ProviderLocationModel } from '../modules/rest/transfer/model/provider-location.model';
import { SnackBarService } from './snackbar.service';
import { ProviderLocationShiftModel } from '../modules/rest/transfer/model/provider-location-shift.model';
import { convertToDate, timeToDate } from '../utils/date.util';

@UntilDestroy()
@Injectable()
export class UserContext {
  private _userContext$ = new BehaviorSubject<CurrentUserContextResponseModel>(undefined);
  private _organizacionaJedinica$ = new BehaviorSubject<OrganizacionaJedinicaModel>(undefined);
  private _employeeInfo$ = new BehaviorSubject<EmployeeModel>(undefined);
  private _spj$ = new BehaviorSubject<StrategijskaPoslovnaJedinicaModel>(undefined);
  private _activnaOrganizacija$ = new BehaviorSubject<OrganizacijaModel>(undefined);
  private _activeOrganization$ = new BehaviorSubject<OrganizationMasterDataModel>(undefined);
  private _aktivnaPoslovnaGodina$ = new BehaviorSubject<PoslovnaGodinaModel>(undefined);
  private _parameters$ = new BehaviorSubject<ParametersModel>(undefined);
  private _activeApplication$ = new BehaviorSubject<ApplicationModel>(undefined);
  private _applicationClicked$ = new Subject<void>();
  private _providerLocation$ = new BehaviorSubject<ProviderLocationModel>(undefined);
  private _providerLocationShift$ = new BehaviorSubject<ProviderLocationShiftModel>(undefined);

  constructor(private store: Store<AppState>,
              private snackBarService: SnackBarService,
              private parametersRestService: ParametersRestService,
              private router: Router,
              private location: Location,
              private objectRestServiceCreator: ObjectRestServiceCreator) {
    this.store.select(CoreUserSelectors.currentUserContext)
      .pipe(untilDestroyed(this))
      .subscribe((val: CurrentUserContextResponseModel) => this._userContext$.next(val));
    this.store.select(CoreOrganizationSelectors.organizacionaJedinica)
      .pipe(untilDestroyed(this))
      .subscribe((val: OrganizacionaJedinicaModel) => this._organizacionaJedinica$.next(val));
    this.store.select(CoreUserSelectors.employeeInfo)
      .pipe(untilDestroyed(this))
      .subscribe((val: EmployeeModel) => this._employeeInfo$.next(val));
    this.store.select(CoreOrganizationSelectors.aktivnaOrganizacija)
      .pipe(untilDestroyed(this))
      .subscribe((val: OrganizacijaModel) => this._activnaOrganizacija$.next(val));
    this.activeOrganization$
      .pipe(untilDestroyed(this))
      .subscribe(val => this._activeOrganization$.next(val));
    this.store.select(CoreOrganizationSelectors.spj)
      .pipe(untilDestroyed(this))
      .subscribe((val: StrategijskaPoslovnaJedinicaModel) => this._spj$.next(val));
    this.store.select(CoreOrganizationSelectors.poslovnaGodina)
      .pipe(untilDestroyed(this))
      .subscribe((val: PoslovnaGodinaModel) => this._aktivnaPoslovnaGodina$.next(val));
    this.store.select(CoreApplicationSelectors.parameters)
      .pipe(untilDestroyed(this))
      .subscribe((val: ParametersModel) => this._parameters$.next({...val}));
    this.listenApplicationChange();
  }

  get userContext$(): Observable<CurrentUserContextResponseModel> {
    return this._userContext$.asObservable();
  }

  get userContext(): CurrentUserContextResponseModel {
    return this._userContext$.value;
  }

  get user$(): Observable<UserResponseModel> {
    return this._userContext$.asObservable()
      .pipe(map(val => val?.user));
  }

  get user(): UserResponseModel {
    return this._userContext$.value?.user;
  }

  get userId(): number {
    return this.user?.id;
  }

  get username(): string {
    return this.user?.username;
  }

  get defaultOrganizationId(): number {
    return this.user?.defaultOrganizationId;
  }

  get parameters(): ParametersModel {
    return this._parameters$.value;
  }

  setParameterValue(key: string, value: any): void {
    this.parametersRestService.setKeyValue(key, value)
      .pipe(untilDestroyed(this))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.openErrorMessage('Error while setting parameter value');
          return throwError(() => error);
        })
      )
      .subscribe(() => {
        this.parameters[key] = value;
      });
  }

  get isOwnerOrAdmin(): boolean {
    return this.user?.systemRole === SystemRoles.OWNER || this.user?.systemRole === SystemRoles.ADMINISTRATOR;
  }

  get organizations$(): Observable<OrganizationMasterDataModel[]> {
    const organizations$ = this.store.select(CoreOrganizationSelectors.organizations);
    return combineLatest([organizations$, this.userContext$])
      .pipe(
        map(([organizations, user]) => {
          return user.organizations
            .map(userOrganization => organizations.find(org => org.id === userOrganization.id))
            .filter(org => !!org);
        })
      );
  }

  get applications$(): Observable<ApplicationModel[]> {
    const applications$ = this.store.select(CoreApplicationSelectors.applications);
    return combineLatest([applications$, this.userContext$])
      .pipe(
        map(([applications, userContext]: [ApplicationModel[], CurrentUserContextResponseModel]) => {
          return applications
            .filter(app => userContext?.applications.some(userApp => userApp.id === app.id));
        })
      );
  }

  get visibleApplications$(): Observable<ApplicationModel[]> {
    return this.applications$
      .pipe(
        map(apps => apps.filter(app => app.visible))
      );
  }

  get activeApplication$(): Observable<ApplicationModel> {
    return this._activeApplication$.asObservable();
  }

  get activeApplication(): ApplicationModel {
    return this._activeApplication$.value;
  }

  get activeOrganizationId$(): Observable<number> {
    return this.userContext$
      .pipe(map(userContext => userContext?.activeOrganizationId));
  }

  get activeOrganizationId(): number {
    return this.userContext.activeOrganizationId;
  }

  get activeOrganization$(): Observable<OrganizationMasterDataModel> {
    const organizations$ = this.store.select(CoreOrganizationSelectors.organizations);
    return combineLatest([this.activeOrganizationId$, organizations$])
      .pipe(
        map(([organizationId, organizations]: [number, OrganizationMasterDataModel[]]) =>
          organizations.find(org => org.id === organizationId)
        )
      );
  }

  get activeOrganization(): OrganizationMasterDataModel {
    return this._activeOrganization$.value;
  }

  get spj(): StrategijskaPoslovnaJedinicaModel {
    return this._spj$.value;
  }

  get aktivnaPoslovnaGodina(): PoslovnaGodinaModel {
    return this._aktivnaPoslovnaGodina$.value;
  }

  get aktivnaOrganizacija(): OrganizacijaModel {
    return this._activnaOrganizacija$.value;
  }

  get organizacionaJedinica(): OrganizacionaJedinicaModel {
    return this._organizacionaJedinica$.value;
  }

  menu$(): Observable<MenuModel> {
    return this.activeApplication$
      .pipe(
        switchMap((app: ApplicationModel) => {
          if (!app) {
            return of(null);
          }
          const applicationRestService = this.objectRestServiceCreator.create(ObjectNameEnum.Application);
          const actionData = new ObjectActionRequestModel({action: 'getApplicationMenu'});
          actionData.put('param', app.id);
          return applicationRestService.doAction(actionData);
        }),
        map((menu: MenuModel) => ({
          ...menu,
          menuItems: this.filterMenuItems(menu?.menuItems),
        }))
      );
  }

  private filterMenuItems(menuItems: MenuItemModel[]): MenuItemModel[] {
    if (!menuItems) {
      return [];
    }
    return menuItems
      .filter(item => this.hasPermissionForMenuItem(item))
      .map(item => ({
        ...item,
        menuItems: this.filterMenuItems(item.menuItems),
      }));
  }

  private hasPermissionForMenuItem(menuItem: MenuItemModel): boolean {
    const _hasRole = !menuItem.onlyVisibleTo || menuItem.onlyVisibleTo.includes(this.userContext.user.systemRole);
    const _hasPermission = !menuItem.permission || hasPermission(this.userContext, menuItem.permission.view, menuItem.permission.actions);
    return _hasRole || _hasPermission;
  }

  get employeeInfo$(): Observable<EmployeeModel> {
    return this._employeeInfo$;
  }

  get currency$(): Observable<string> {
    return this.activeOrganization$
      .pipe(map(org => org?.organizationReferences?.place?.country?.currency?.designation));
  }

  get ldapActive$(): Observable<boolean> {
    return this.store.select(CoreApplicationSelectors.parameters)
      .pipe(
        map(parameters => parameters?.ldapActive)
      );
  }

  private listenApplicationChange(): void {
    const urlChange$: Observable<any> = this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd)
      );

    const selectedApplicationName$ = urlChange$
      .pipe(
        startWith(window.location.hash),
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        map(() => this.getApplicationName()),
        distinctUntilChanged()
      );

    selectedApplicationName$
      .pipe(
        switchMap((app) =>
          this.store.select(CoreApplicationSelectors.application(app)))
      )
      .subscribe((app: ApplicationModel) => this._activeApplication$.next(app));
  }

  private getApplicationName(): string {
    const hash = this.location.path().slice(1);
    const queryParamIndex = hash.indexOf('?');
    const appName = queryParamIndex === -1 ? hash : hash.slice(0, queryParamIndex);
    return decodeURI(appName);
  }

  get applicationClicked$(): Observable<void> {
    return this._applicationClicked$;
  }

  applicationClicked(): void {
    this._applicationClicked$.next();
  }

  get providerLocation(): ProviderLocationModel {
    return this._providerLocation$.value;
  }

  setProviderLocation(providerLocation: ProviderLocationModel): void {
    this._providerLocation$.next(providerLocation);
  }

  removeProviderLocation(): void {
    this._providerLocation$.next(null);
  }

  get providerLocationShift(): ProviderLocationShiftModel {
    return this._providerLocationShift$.value;
  }

  setProviderLocationShift(shift: ProviderLocationShiftModel): void {
    this._providerLocationShift$.next(shift);
  }

  removeProviderLocationShift(): void {
    this._providerLocationShift$.next(null);
  }

  get bettingLoginAllowed$(): Observable<boolean> {
    return interval(1000)
      .pipe(
        startWith(this.isBettingLoginAllowed()),
        map(() => {
          return this.isBettingLoginAllowed();
        }),
        distinctUntilChanged()
      );
  }

  isBettingLoginAllowed(): boolean {
    if (!this.parameters?.loginToBettingForbiddenFrom || !this.parameters?.loginToBettingForbiddenTo) {
      return true;
    }

    const loginForbiddenFrom = timeToDate(this.parameters.loginToBettingForbiddenFrom);
    const loginForbiddenTo = timeToDate(this.parameters.loginToBettingForbiddenTo);
    if (!loginForbiddenFrom || !loginForbiddenTo) {
      return true;
    }

    const currentTime = convertToDate(new Date(), false);
    return !currentTime.isBetween(loginForbiddenFrom, loginForbiddenTo);
  }
}

<div class="c-bui-icon-container"
  [ngClass]="[
    size ? 'u-icon-size-' + (size | toCssClassName) : ''
  ]">
  <app-bui-icon
    [name]="name"
    [inline]="inline"
    [type]="type">
  </app-bui-icon>
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSizesEnum } from '@shared/modules/bui/bui-icon/enums/icon-sizes.enum';

@Component({
  selector: 'app-bui-tooltip-icon',
  templateUrl: './bui-tooltip-icon.component.html',
  styleUrls: ['./bui-tooltip-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuiTooltipIconComponent {
  @Input() tooltip: string;

  IconSizesEnum = IconSizesEnum;
}

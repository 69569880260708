import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  DialogTemplateCloseService
} from '@shared/components/templates/dialog-template/services/dialog-template-close.service';

@Component({
  selector: 'app-dialog-template-header-actions',
  templateUrl: './dialog-template-header-actions.component.html',
  styleUrls: ['./dialog-template-header-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTemplateHeaderActionsComponent {
  @Input() showClose = true;
  @Input() isDisabled = false;

  constructor(private dialogTemplateCloseService: DialogTemplateCloseService) {
  }

  close(): void {
    this.dialogTemplateCloseService.closeDialog();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { BUI_ICON_TYPE_MAT } from '@base/utils/icon-types.util';
import { SnackBarService } from '@base/services/snackbar.service';
import { saveStringAsFile } from '../../../util/file.util';
import { DialogTemplateButtonComboStyleEnum } from '../../templates/dialog-template/enums/dialog-template-button.enum';

@Component({
  selector: 'app-application-error-details-dialog',
  templateUrl: './application-error-details-dialog.component.html',
  styleUrls: ['./application-error-details-dialog.component.scss'],
})
export class ApplicationErrorDetailsDialogComponent implements OnInit {

  descriptionFormControl: FormControl;
  DialogTemplateButtonComboStyleEnum = DialogTemplateButtonComboStyleEnum;

  constructor(private dialogRef: MatDialogRef<ApplicationErrorDetailsDialogComponent>,
              private snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    const message = `${this.data.message} ${this.data.stackTrace}`;
    this.descriptionFormControl = new FormControl<string>(message);
  }

  static open(dialog: MatDialog, data: any): MatDialogRef<ApplicationErrorDetailsDialogComponent, void> {
    return dialog.open(ApplicationErrorDetailsDialogComponent, {
      width: '1200px',
      panelClass: 'dialog-fixed-height',
      data,
    });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onDownloadClick(): void {
    const fileContent = `${this.data.message} ${this.data.stackTrace}`;
    saveStringAsFile(fileContent, '.txt', 'Error_stack trace');
  }

  showConfirmation(): void {
    this.snackBarService.openSuccessMessage('MORE_DETAIL_ERROR_DIALOG.COPY_INFO');
  }

  protected readonly BUI_ICON_TYPE_MAT = BUI_ICON_TYPE_MAT;
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { FilterItemModel } from '@base/modules/rest/smart-reports/model/filter-item.model';
import { SmartReportExecuteSqlModel } from '@base/modules/rest/smart-reports/model/smart-report-execute-sql.model';
import { SmartReportModel } from '@base/modules/rest/smart-reports/model/smart-report.model';
import { Observable } from 'rxjs';
import { SelectOptionModel } from '@shared/components/form-elements/select/model/select.option.model';
import {
  ReportViewEnum
} from '@views/secured/beyond-smart-reports/modules/rest/smart-report/model/enum/report-view.enum';
import { AppConfiguration } from '../../../../app-configuration';
import { BeyondPaginationModel } from '../../../model/common/beyond-pagination.model';
import { UserResponseModel } from '@base/modules/rest/user/response/user-response.model';

@Injectable()
export class SmartReportRestService {

  private readonly smartReportsUrl: string = this.appConfiguration.serverApiUrl + '/secured/smart-reports';

  constructor(private http: HttpClient, private appConfiguration: AppConfiguration) {
  }

  find(payload: {
    pagination: BeyondPaginationModel;
    reportView: ReportViewEnum,
  }): Observable<PaginationResponseModel<SmartReportModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter,
      reportView: payload.reportView,
    };
    return this.http.get<PaginationResponseModel<SmartReportModel>>(this.smartReportsUrl, {params: queryParams});
  }

  getOne(payload: { id: number; preventForbiddenRedirect?: boolean }): Observable<SmartReportModel> {
    let headers = new HttpHeaders();
    if (payload.preventForbiddenRedirect) {
      headers = headers.set('prevent_forbidden_redirection', `${payload.preventForbiddenRedirect}`);
    }
    return this.http.get<SmartReportModel>(`${this.smartReportsUrl}/${payload.id}`, {headers});
  }

  create(smartReport: SmartReportModel): Observable<SmartReportModel> {
    return this.http.post<SmartReportModel>(this.smartReportsUrl, smartReport);
  }

  update(smartReport: SmartReportModel): Observable<SmartReportModel> {
    return this.http.put<SmartReportModel>(`${this.smartReportsUrl}/${smartReport.id}`, smartReport);
  }

  copySmartReport(payload: { id: number; smartReport: SmartReportModel }): Observable<SmartReportModel> {
    return this.http.post<SmartReportModel>(`${this.smartReportsUrl}/${payload.id}/copy`, payload.smartReport);
  }

  changeActivity(payload: { data: { smartReportId: number; active: boolean }[] }): Observable<SmartReportModel[]> {
    return this.http.post<SmartReportModel[]>(`${this.smartReportsUrl}/change-activity`, payload.data);
  }

  deleteReport(id: number): Observable<number> {
    return this.http.delete<number>(`${this.smartReportsUrl}/${id}`);
  }

  deleteReports(smartReports: number[]): Observable<any> {
    return this.http.post<any>(`${this.smartReportsUrl}/bulk`, smartReports);
  }

  checkReport(smartReport: SmartReportModel): Observable<SmartReportExecuteSqlModel> {
    return this.http.post<SmartReportExecuteSqlModel>(`${this.smartReportsUrl}/execute-sql`, smartReport);
  }

  executeOnlySQLQuery(sqlQuery: string): Observable<SelectOptionModel<any>[]> {
    return this.http.post<SelectOptionModel<any>[]>(`${this.smartReportsUrl}/execute-filter-sql`, sqlQuery);
  }

  getSmartReportResultSet(payload: { id: number; filters: FilterItemModel[] }): Observable<any> {
    return this.http.post(`${this.smartReportsUrl}/result-set`, {
      'filters': payload.filters,
      'reportId': payload.id,
    });
  }

  saveSettings(payload: { smartReportId: number; settings: string }): Observable<SmartReportModel> {
    return this.http.post<SmartReportModel>(`${this.smartReportsUrl}/${payload.smartReportId}/settings`, payload.settings);
  }

  saveSettingsForRole(payload: {
    smartReportId: number;
    smartReportRoleId: number;
    settings: string;
  }): Observable<SmartReportModel> {
    return this.http.post<SmartReportModel>(`${this.smartReportsUrl}/${payload.smartReportId}/smart-report-roles/${payload.smartReportRoleId}/settings`, payload.settings);
  }

  renameSmartReportRole(payload: {
    smartReportId: number;
    smartReportRoleId: number;
    name: string;
  }): Observable<SmartReportModel> {
    return this.http.post<SmartReportModel>(`${this.smartReportsUrl}/${payload.smartReportId}/smart-report-roles/${payload.smartReportRoleId}/name`, payload.name);
  }

  setDefaultSmartReportRole(payload: {
    smartReportId: number;
    smartReportRoleId: number
  }): Observable<SmartReportModel> {
    return this.http.patch<SmartReportModel>(`${this.smartReportsUrl}/${payload.smartReportId}/smart-report-roles/default`, {'roleId': payload.smartReportRoleId});
  }

  getLocalizationJson(lang: string): Observable<any> {
    return this.http.get(`assets/i18n-flexmonster/${lang}.json`);
  }

  findUsersByRoles(payload: { pagination: BeyondPaginationModel; smartReportId: number }): Observable<PaginationResponseModel<UserResponseModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter || '',
    };
    return this.http.get<PaginationResponseModel<UserResponseModel>>(`${this.smartReportsUrl}/${payload.smartReportId}/users-with-roles`, {params: queryParams});
  }

  getDataSources(): Observable<string[]> {
    return this.http.get<string[]>(`${this.smartReportsUrl}/data-sources/secondary`);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbsModule } from '@base/modules/breadcrumbs/breadcrumbs.module';
import { LayoutContentMainComponent } from './components/layout-content-main/layout-content-main.component';
import { LayoutContentMainBodyDirective } from './directives/layout-content-main-body.directive';
import { LayoutContentMainHeaderActionsDirective } from './directives/layout-content-main-header-actions.directive';
import {
  LayoutContentMainHeaderActiveFiltersDirective
} from './directives/layout-content-main-header-active-filters.directive';
import {
  LayoutContentMainHeaderFiltersActionsDirective
} from './directives/layout-content-main-header-filters-actions.directive';
import {
  LayoutContentMainHeaderFiltersSearchDirective
} from './directives/layout-content-main-header-filters-search.directive';
import { LayoutContentMainHeaderTitleDirective } from './directives/layout-content-main-header-title.directive';
import {
  LayoutContentMainHeaderViewTemplatesDirective
} from './directives/layout-content-main-header-view-templates.directive';

@NgModule({
  declarations: [
    LayoutContentMainComponent,
    LayoutContentMainBodyDirective,
    LayoutContentMainHeaderTitleDirective,
    LayoutContentMainHeaderActionsDirective,
    LayoutContentMainHeaderFiltersSearchDirective,
    LayoutContentMainHeaderFiltersActionsDirective,
    LayoutContentMainHeaderViewTemplatesDirective,
    LayoutContentMainHeaderActiveFiltersDirective,
  ],
  imports: [
    CommonModule,
    BreadcrumbsModule,
  ],
  exports: [
    LayoutContentMainComponent,
    LayoutContentMainBodyDirective,
    LayoutContentMainHeaderTitleDirective,
    LayoutContentMainHeaderActionsDirective,
    LayoutContentMainHeaderFiltersSearchDirective,
    LayoutContentMainHeaderFiltersActionsDirective,
    LayoutContentMainHeaderViewTemplatesDirective,
    LayoutContentMainHeaderActiveFiltersDirective,
  ],
})
export class LayoutContentMainModule {
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { McmWizardRestAdapterService } from '@base/modules/rest/mcm-wizard/mcm-wizard-rest-adapter.service';
import { OrganizationRestAdapterService } from '@base/modules/rest/organization/organization-rest-adapter.service';
import { BbsObjectRestAdapterService } from '@base/modules/rest/bbs-object/bbs-object-rest-adapter.service';
import { CustomFieldRestAdapterService } from '@base/modules/rest/custom-field/custom-field-rest-adapter.service';
import { LicenseRestAdapterService } from '@base/modules/rest/license/license-rest-adapter.service';
import {
  UserOnlineActivityRestAdapterService
} from '@base/modules/rest/user/user-online-activity-rest-adapter.service';
import {
  OrganizationDiagramRestAdapterService
} from '@base/modules/rest/organization/organization-diagram-rest-adapter.service';
import { ObjectNameEnum } from '../master-data-history/model/enums/object-name.enum';
import { AppConfiguration } from '../../../../app-configuration';
import { FileRestUtilsService } from '../common/file-rest-utils.service';
import { SmartReportRestAdapterService } from '../smart-reports/smart-report-rest-adapter.service';
import { ObjectRestService } from './object-rest.service';

@Injectable()
export class ObjectRestServiceCreator {

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration,
              private fileRestUtilsService: FileRestUtilsService,
              private mcmWizardRestAdapterService: McmWizardRestAdapterService,
              private organizationRestAdapterService: OrganizationRestAdapterService,
              private customFieldRestAdapterService: CustomFieldRestAdapterService,
              private licenseRestAdapterService: LicenseRestAdapterService,
              private userOnlineActivityRestAdapterService: UserOnlineActivityRestAdapterService,
              private organizationDiagramRestAdapterService: OrganizationDiagramRestAdapterService,
              private bbsObjectsRestAdapterService: BbsObjectRestAdapterService,
              private smartReportRestAdapterService: SmartReportRestAdapterService) {
  }

  create<T = any>(objectName: string): ObjectRestService<T> {
    if (objectName === ObjectNameEnum.MCMWizard) {
      return this.mcmWizardRestAdapterService;
    }
    if (objectName === ObjectNameEnum.Organization) {
      return this.organizationRestAdapterService;
    }
    if (objectName === ObjectNameEnum.BbsObject) {
      return this.bbsObjectsRestAdapterService;
    }
    if (objectName === ObjectNameEnum.CustomField) {
      return this.customFieldRestAdapterService;
    }
    if (objectName === ObjectNameEnum.License) {
      return this.licenseRestAdapterService;
    }
    if (objectName === ObjectNameEnum.UserActivity) {
      return this.userOnlineActivityRestAdapterService;
    }
    if (objectName === ObjectNameEnum.OrganizationDiagram) {
      return this.organizationDiagramRestAdapterService;
    }
    if (objectName === ObjectNameEnum.SmartReport) {
      return this.smartReportRestAdapterService;
    }
    return new ObjectRestService<T>(this.httpClient, this.appConfiguration, objectName, this.fileRestUtilsService);
  }
}

import { Component, Input } from '@angular/core';
import { DialogTemplateIconColorEnum } from '@shared/components/templates/dialog-template/enums/dialog-template-icon-color.enum';

@Component({
  selector: 'app-dialog-template-icon',
  templateUrl: './dialog-template-icon.component.html',
  styleUrls: ['./dialog-template-icon.component.scss'],
})
export class DialogTemplateIconComponent {
  @Input() name: string;
  @Input() color: DialogTemplateIconColorEnum = DialogTemplateIconColorEnum.PRIMARY;
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoaderDialogComponent } from '@shared/components/dialogs/loader-dialog/loader-dialog.component';
import { reloadContext } from '../../utils/route.util';
import * as fromActions from './actions';

@Injectable()
export class CoreOrganizationEffects {

  constructor(private actions$: Actions,
              private router: Router,
              private route: ActivatedRoute,
              private matDialog: MatDialog) {
  }

  setPoslovnaGodina$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.SetPoslovnaGodina),
        tap(() => {
          LoaderDialogComponent.open(this.matDialog);
        }),
        tap((action) => {
          const businessYear = action.poslovnaGodina?.id ? action.poslovnaGodina.id.toString() : null;
          if (businessYear) {
            localStorage.setItem('business_year', businessYear);
          } else {
            localStorage.removeItem('business_year');
          }
          reloadContext(this.router, this.route);
        })
      )
    , {dispatch: false});
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar,
              private translate: TranslateService) {
  }

  openInfoMessage(key: string): void {
    const message = key ? this.translate.instant(key) : '';
    this.snackBar.open(message, '', {
      panelClass: 'mat-snack-bar--bui-info',
      duration: 3000,
    });
  }

  openSuccessMessage(key: string): void {
    const message = key ? this.translate.instant(key) : '';
    this.snackBar.open(message, '', {
      panelClass: 'mat-snack-bar--bui-success',
      duration: 3000,
    });
  }

  openErrorMessage(key: string | HttpErrorResponse): void {
    const isKey = typeof key === 'string';
    let message = '';
    let errorCode = '';
    let stackTrace = '';
    if (isKey) {
      message = key ? this.translate.instant(key) : '';
      errorCode = key ? this.translate.instant(key) : '';
    } else {
      message = key.error.message ? this.translate.instant(key.error.message as string) : '';
      errorCode = key.error.errorCode ? this.translate.instant(key.error.errorCode as string) : '';
      stackTrace = key.error.stackTrace;
    }

    const hasActions = !!stackTrace;
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: 'mat-snack-bar--bui-error',
      duration: isKey ? 3000 : null,
      data: {message, errorCode, stackTrace, closeSnackBar: () => this.snackBar.dismiss(), hasActions: hasActions},
    });
  }
}

import { BreakpointObserver } from '@angular/cdk/layout';
import { Directive, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[appBreakpointObserver]',
})
export class BreakpointObserverDirective implements OnInit {
  @Input('appBreakpointObserver') breakpoint: string;
  @Output() observedBreakpointChanged = new EventEmitter();

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(this.breakpoint)
      .pipe(
        untilDestroyed(this),
        map(result => result.matches),
        distinctUntilChanged()
      )
      .subscribe(match => {
        this.observedBreakpointChanged.next(match);
      });
  }
}

@NgModule({
  declarations: [
    BreakpointObserverDirective,
  ],
  exports: [
    BreakpointObserverDirective,
  ],
})
export class BreakpointObserverModule {
}

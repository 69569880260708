import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optionalFieldTranslate',
})
export class OptionalFieldTranslatePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }
    return `${value}`;
  }
}

@NgModule({
  exports: [
    OptionalFieldTranslatePipe,
  ],
  declarations: [
    OptionalFieldTranslatePipe,
  ],
})
export class OptionalFieldTranslatePipeModule {
}

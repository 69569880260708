<button
  type="button"
  class="c-bui-button-label"
  [ngClass]="[
    backgroundStyle ? 'c-bui-button-label--background-' + backgroundStyle : '',
    borderStyle ? 'c-bui-button-label--border-' + borderStyle : '',
    contentType ? 'c-bui-button-label--content-type-' + contentType : '',
    contentColor ? 'c-bui-button-label--content-color-' + contentColor : '',
    isFullWide ? 'c-bui-button-label--is-full-wide' : '',
    disabled ? 'u-disabled' : '',
    isLink ? 'c-bui-button-label--is-link' : '',
  ]"
  matRipple
  [matRippleDisabled]="comboStyle === ('naked-dark' || 'naked-link')"
  [disabled]="disabled"
  [tabindex]="disabled ? '-1' : tabIndex"
  (click)="onClick($event)">

  <span class="c-bui-button-label__content"
    [ngClass]="[
      contentColor ? 'c-bui-button-label__content--color-' + contentColor : '',
      contentStyle ? 'c-bui-button-label__content--style-' + contentStyle : '',
    ]">
    <ng-content></ng-content>
  </span>

  <ng-container *ngIf="contentType === 'dropdown'">
    <span class="c-bui-button-label__dropdown-caret"
      [ngClass]="[
        dropdownCaretColor ? 'c-bui-button-label__dropdown-caret--color-' + dropdownCaretColor : '',
      ]">
      <mat-icon [svgIcon]="'mdi:menu-down'"></mat-icon>
    </span>
  </ng-container>

</button>

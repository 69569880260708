export enum ObjectNameEnum {
  AktivacijaOS = 'AktivacijaOS',
  AktivacijaOSSt = 'AktivacijaOSSt',
  AmortizacijaOS = 'AmortizacijaOS',
  AmortizacijaOSSt = 'AmortizacijaOSSt',
  AmortizacionaGrupa = 'AmortizacionaGrupa',
  AmortizacionaGrupaSt = 'AmortizacionaGrupaSt',
  AmortizacionaPodgrupaOS = 'AmortizacionaPodgrupaOS',
  AmortizacionaPodgrupaOSSt = 'AmortizacionaPodgrupaOSSt',
  AmortizacionaPodgrupaOSStKnjigaAm = 'AmortizacionaPodgrupaOSStKnjigaAm',
  AnalitickaKarticaRobeUMagacinu = 'AnalitickaKarticaRobeUMagacinu',
  Analitika = 'Analitika',
  AopKonto = 'AopKonto',
  AopKontoSt = 'AopKontoSt',
  AutomatskoPreknjizavanje = 'AutomatskoPreknjizavanje',
  AutomatskoPreknjizavanjeSt = 'AutomatskoPreknjizavanjeSt',
  Application = 'Application',
  AvansiDobavljaca = 'AvansiDobavljaca',
  AvansiKupaca = 'AvansiKupaca',
  Banka = 'Banka',
  BankDeposit = 'BankDeposit',
  BankDepositItems = 'BankDepositItems',
  BankovniRacunLica = 'BankovniRacunLica',
  Blagajna = 'Blagajna',
  BrutoBilans = 'BrutoBilans',
  BrutoBilansStSummary = 'BrutoBilansStSummary',
  Category = 'Category',
  CMSDailyReport = 'CMSDailyReport',
  CMSDailyReport2 = 'CMSDailyReport2',
  ControllingGrupaKontaKonto = 'ControllingGrupaKontaKonto',
  Country = 'Country',
  ControlPanel = 'ControlPanel',
  Currency = 'Currency',
  CustomField = 'CustomField',
  Customer = 'Customer',
  Dashboard = 'Dashboard',
  DashboardView = 'DashboardView',
  DashboardWidget = 'DashboardWidget',
  DevopsManagement = 'DevopsManagement',
  District = 'District',
  DnevnikBlagajne = 'DnevnikBlagajne',
  DnevnikBlagajneSummary = 'DnevnikBlagajneSummary',
  DnevnikGlavneKnjige = 'DnevnikGlavneKnjige',
  Dokument = 'Dokument',
  Document = 'Document',
  DokumentMenadzer = 'DokumentMenadzer',
  Mis2OpenOdobravanje = 'Mis2OpenOdobravanje',
  DokumentBlagajneSt = 'DokumentBlagajneSt',
  DokumentDokumenta = 'DokumentDokumenta',
  Drzava = 'Drzava',
  Eksploatacije = 'Eksploatacije',
  ElektronskiIzvod = 'ElektronskiIzvod',
  ElektronskiIzvodDevizni = 'ElektronskiIzvodDevizni',
  ExchangeList = 'ExchangeList',
  Favorite = 'Favorite',
  FiscalYear = 'FiscalYear',
  GodisnjiIzvestajOS = 'GodisnjiIzvestajOS',
  GrupaKarakteristikaRobe = 'GrupaKarakteristikaRobe',
  GrupaLinijeDistribucije = 'GrupaLinijeDistribucije',
  GrupaTipovaKarakteristika = 'GrupaTipovaKarakteristika',
  GrupnaObradaFaktura = 'GrupnaObradaFaktura',
  InternaSifraPlacanja = 'InternaSifraPlacanja',
  IsplatnicaBlagajne = 'IsplatnicaBlagajne',
  IsplatnicaBlagajneSt = 'IsplatnicaBlagajneSt',
  IzlazneAvansneUplate = 'IzlazneAvansneUplate',
  IzlazniAvansniRacun = 'IzlazniAvansniRacun',
  IzlazniAvansniRacunSt = 'IzlazniAvansniRacunSt',
  IzlazniDokument = 'IzlazniDokument',
  IzlazniDokumentSt = 'IzlazniDokumentSt',
  IzlazniUsluga = 'IzlazniUsluga',
  IzlazniUslugaSt = 'IzlazniUslugaSt',
  IzmenaAnalitikeOS = 'IzmenaAnalitikeOS',
  IzmenaAnalitikeOSSt = 'IzmenaAnalitikeOSSt',
  IzvestajPoLokacijiDialog = 'IzvestajPoLokacijiDialog',
  IzlazniRacunOS = 'IzlazniRacunOS',
  IzlazniRacunOSSt = 'IzlazniRacunOSSt',
  IzvestajPoLokacji = 'IzvestajPoLokaciji',
  IzvestajZaKvalitetaPoVrstiProizvoda = 'IzvestajZaKvalitetaPoVrstiProizvoda',
  IzvestajZaKvalitetaPoVrstiProizvodaDialog = 'IzvestajZaKvalitetaPoVrstiProizvodaDialog',
  IzvestajZaKvalitetaPoVrstiProizvodaDialogSt = 'IzvestajZaKvalitetaPoVrstiProizvodaDialogSt',
  IzvestajZaKvalitetaPoVrstiProizvodaGreska = 'IzvestajZaKvalitetaPoVrstiProizvodaGreska',
  Izvod1 = 'Izvod1',
  IzvodSt1 = 'IzvodSt1',
  JedinicaMereZaRobu = 'JedinicaMereZaRobu',
  Karakteristika = 'Karakteristika',
  KarakteristikeRobe = 'KarakteristikeRobe',
  KarticaDobavljacaFinansijska = 'KarticaDobavljacaFinansijska',
  KarticaDobavljacaFinansijskaSummary = 'KarticaDobavljacaFinansijskaSummary',
  KarticaDobavljacaFinansijskaDevizna = 'KarticaDobavljacaFinansijskaDevizna',
  KarticaDobavljacaFinansijskaDeviznaSummary = 'KarticaDobavljacaFinansijskaDeviznaSummary',
  KarticaKatPodOS = 'KarticaKatPodOS',
  KarticaKupcaFinansijska = 'KarticaKupcaFinansijska',
  KarticaKupcaFinansijskaSummary = 'KarticaKupcaFinansijskaSummary',
  KarticaKupcaFinansijskaDevizna = 'KarticaKupcaFinansijskaDevizna',
  KarticaKupcaFinansijskaDeviznaSummary = 'KarticaKupcaFinansijskaDeviznaSummary',
  KarticaOS = 'KarticaOS',
  KarticaOSPoreska = 'KarticaOSPoreska',
  KarticaOSPriprema = 'KarticaOSPriprema',
  KasaBlok1 = 'KasaBlok1',
  KasaBlok1St = 'KasaBlok1St',
  KasaBlok1Cekovi = 'KasaBlok1Cekovi',
  KasaBlok1Kartice = 'KasaBlok1Kartice',
  KasaBlok1Usluge = 'KasaBlok1Usluge',
  KategorijeVrsteRada = 'KategorijeVrsteRada',
  KategorijeVrsteRadaSt = 'KategorijeVrsteRadaSt',
  KlasaPartnera1 = 'KlasaPartnera1',
  KlasaPartnera1NadredjenaKlasa = 'KlasaPartnera1NadredjenaKlasa',
  KlasaRobe1 = 'KlasaRobe1',
  KlasaRobe1Roba = 'KlasaRobe1Roba',
  KlasaRobe1NadredjenaKlasa = 'KlasaRobe1NadredjenaKlasa',
  KlasaRobe1RobnaGrupa = 'KlasaRobe1RobnaGrupa',
  KlasifikacijaOS = 'KlasifikacijaOS',
  KnjigaAmortizacije = 'KnjigaAmortizacije',
  KnjigaAmortizacijeSt = 'KnjigaAmortizacijeSt',
  KnjigaIzdatihRacuna = 'KnjigaIzdatihRacuna',
  KnjigaIzlaznihRacunaPDVa1 = 'KnjigaIzlaznihRacunaPDVa1',
  KnjigaPrimljenihRacuna = 'KnjigaPrimljenihRacuna',
  KnjigaUlaznihRacunaPDVa1 = 'KnjigaUlaznihRacunaPDVa1',
  KnjiznoPismo = 'KnjiznoPismo',
  KnjiznoPismoSt = 'KnjiznoPismoSt',
  KnjiznoPismoVirmani = 'KnjiznoPismoVirmani',
  Komentar = 'Komentar',
  KontniPlan = 'KontniPlan',
  KontoKontnogPlana = 'KontoKontnogPlana',
  KorekcijaGlavneKnjige = 'KorekcijaGlavneKnjige',
  KursnaListaCentralneBanke = 'KursnaListaCentralneBanke',
  KursnaListaCentralneBankeSt = 'KursnaListaCentralneBankeSt',
  Layout = 'Layout',
  License = 'License',
  LinijaDistribucije1 = 'LinijaDistribucije1',
  LinijaDistribucijeStVoziloMesto = 'LinijaDistribucijeStVoziloMesto',
  LokacijaMagacina = 'LokacijaMagacina',
  LokacijaOS = 'LokacijaOS',
  MCMWizard = 'McmWizard',
  Magacin = 'Magacin',
  MasovnoGenerisanjeIzlaznihAvansnihRacuna = 'MasovnoGenerisanjeIzlaznihAvansnihRacuna',
  MasovnoPovezivanjeFakturaIAvansnihRacuna = 'MasovnoPovezivanjeFakturaIAvansnihRacuna',
  MasovnoGenerisanjeOpomena = 'MasovnoGenerisanjeOpomena',
  MasovnoGenerisanjeOpomenaFakture = 'MasovnoGenerisanjeOpomenaFakture',
  Menu = 'Menu',
  MenuItem = 'MenuItem',
  MestoTroska = 'MestoTroska',
  Municipality = 'Municipality',
  NacinPopisaOS = 'NacinPopisaOS',
  NadlezniZaOS = 'NadlezniZaOS',
  NalKnjPlateAH = 'NalKnjPlateAH',
  NalKnjPlateAHSt = 'NalKnjPlateAHSt',
  Nalog = 'Nalog',
  NivoKlaseRobe1 = 'NivoKlaseRobe1',
  Normativ = 'Normativ',
  NosiocTroskaOsnovni = 'NosiocTroskaOsnovni',
  ObjectAttachment = 'ObjectAttachment',
  BbsObject = 'BbsObject',
  ObracunKursnihRazlika = 'ObracunKursnihRazlika',
  ObracunKursnihRazlikaSt = 'ObracunKursnihRazlikaSt',
  ObracunataAmortizacija = 'ObracunataAmortizacija',
  Opstina = 'Opstina',
  Opomena = 'Opomena',
  OpomenaSt = 'OpomenaSt',
  Organizacija = 'Organizacija',
  Organization = 'Organization',
  OrganizacionaJedinica = 'OrganizacionaJedinica',
  OrganizationDiagram = 'OrganizationDiagram',
  OsamStope = 'OsamStope',
  OsnovUplateIsplate = 'OsnovUplateIsplate',
  OsnovUplateIsplateDodatniSati = 'OsnovUplateIsplateDodatniSati',
  OsnovnoSredstvo = 'OsnovnoSredstvo',
  OsnovnoSredstvoDelatnost = 'OsnovnoSredstvoDelatnost',
  OsnovnoSredstvoServisi = 'OsnovnoSredstvoServisi',
  OsnovnoSredstvoTrosCen = 'OsnovnoSredstvoTrosCen',
  OtvaranjeZatvorenihStavkiGK = 'OtvaranjeZatvorenihStavkiGK',
  OtvoreneStavkeGlavneKnjige = 'OtvoreneStavkeGlavneKnjige',
  OtvoreneStavkeGlavneKnjigeSummary = 'OtvoreneStavkeGlavneKnjigeSummary',
  Pakovanje2 = 'Pakovanje2',
  ParametriPlacanjaSvrhaDozVirman = 'ParametriPlacanjaSvrhaDozVirman',
  PaymentCard = 'PaymentCard',
  PeriodKnjizenja = 'PeriodKnjizenja',
  Permission = 'Permission',
  Place = 'Place',
  PlanPlacanja = 'PlanPlacanja',
  PlanPlacanjaVirman = 'PlanPlacanjaVirman',
  PlanPlacanjaInterniPrenosSredstava = 'PlanPlacanjaInterniPrenosSredstava',
  PlanPlacanjaStanje = 'PlanPlacanjaStanje',
  PlanPlacanjaOdliv = 'PlanPlacanjaOdliv',
  PlanPlacanjaPriliv = 'PlanPlacanjaPriliv',
  PlanPlacanjaPrenos = 'PlanPlacanjaPrenos',
  PodaciKlase = 'PodaciKlase',
  PodaciKlasePartnera = 'PodaciKlasePartnera',
  PodrucjePrimeneTarifneGrupe = 'PodrucjePrimeneTarifneGrupe',
  PopdvprijavaNew = 'PopdvprijavaNew',
  PopisOS = 'PopisOS',
  PopisOSSt = 'PopisOSSt',
  PopisOSStRezultatiPopisa = 'PopisOSStRezultatiPopisa',
  PoreskaAmortizacija = 'PoreskaAmortizacija',
  PoreskaPrijava = 'PoreskaPrijava',
  PoreskaPrijavaKnjizeneStavke = 'PoreskaPrijavaKnjizeneStavke',
  PoreskaPrijavaSt = 'PoreskaPrijavaSt',
  PoslovnaGodina = 'PoslovnaGodina',
  PoslovniPartnerPravnoLice = 'PoslovniPartnerPravnoLice',
  PravnoLiceOrganizacija = 'PravnoLiceOrganizacija',
  PregledDospecaKupacaPoDanima = 'PregledDospecaKupacaPoDanima',
  PregledDospecaKupacaPoDanimaDetaljno = 'PregledDospecaKupacaPoDanimaDetaljno',
  PregledDospecaKupacaPoDanimaSummary = 'PregledDospecaKupacaPoDanimaSummary',
  PregledDospelihObavezaKupacaPoPeriodimaSaPresekomNaDan2T = 'PregledDospelihObavezaKupacaPoPeriodimaSaPresekomNaDan2T',
  PregledDospelihObavezaKupacaPoPeriodimaSaPresekomNaDan2TDetaljno = 'PregledDospelihObavezaKupacaPoPeriodimaSaPresekomNaDan2TDetaljno',
  PregledDospelihObavezaKupacaPoPeriodimaSaPresekomNaDan2TSummary = 'PregledDospelihObavezaKupacaPoPeriodimaSaPresekomNaDan2TSummary',
  PregledKnjizenjaNaKarticamaDobavljaca = 'PregledKnjizenjaNaKarticamaDobavljaca',
  PregledKnjizenjaNaKarticamaDobavljacaSummary = 'PregledKnjizenjaNaKarticamaDobavljacaSummary',
  PregledKnjizenjaNaKarticamaKupca = 'PregledKnjizenjaNaKarticamaKupca',
  PregledKnjizenjaNaKarticamaKupcaSummary = 'PregledKnjizenjaNaKarticamaKupcaSummary',
  PregledOS = 'PregledOS',
  PregledKarticeOS = 'PregledKarticeOS',
  PregledKnjizenjaNaKontu = 'PregledKnjizenjaNaKontu',
  PregledKnjizenjaNaKontuSummary = 'PregledKnjizenjaNaKontuSummary',
  PregledPoKontimaIAnalitikama = 'PregledPoKontimaIAnalitikama',
  PregledPoKontimaIAnalitikamaSummary = 'PregledPoKontimaIAnalitikamaSummary',
  PregledPrometaDobavljaca = 'PregledPrometaDobavljaca',
  PregledPrometaDobavljacaSummary = 'PregledPrometaDobavljacaSummary',
  PregledPrometaKontaPoAnalitikama = 'PregledPrometaKontaPoAnalitikama',
  PregledPrometaKontaPoAnalitikamaSummary = 'PregledPrometaKontaPoAnalitikamaSummary',
  PregledPrometaKupaca = 'PregledPrometaKupaca',
  PregledPrometaKupacaSummary = 'PregledPrometaKupacaSummary',
  PriceList = 'PriceList',
  PrijemnicaOS = 'PrijemnicaOS',
  PrijemnicaOSSt = 'PrijemnicaOSSt',
  PrijemnicaTroskovnica = 'PrijemnicaTroskovnica',
  PrijemnicaTroskovnicaSt = 'PrijemnicaTroskovnicaSt',
  PrintTemplate = 'PrintTemplate',
  PrintTemplatesDialog = 'PrintTemplatesDialog',
  ProOperacija = 'ProOperacija',
  Product = 'Product',
  PromenaPlanskeCene = 'PromenaPlanskeCene1',
  PromenaPlanskeCeneSt = 'PromenaPlanskeCene1St',
  ProviderLocation = 'ProviderLocation',
  ProviderLocationShift = 'ProviderLocationShift',
  ProviderLocationUser = 'ProviderLocationUser',
  PutniNalogKomercijalni = 'PutniNalogKomercijalni',
  PutniNalogKomercijalniAkontacije = 'PutniNalogKomercijalniAkontacije',
  PutniNalogKomercijalniVirman = 'PutniNalogKomercijalniVirman',
  PutniNalogKomercijalniPutniRacun = 'PutniNalogKomercijalniPutniRacun',
  PutniRacun = 'PutniRacun',
  PutniRacunKomercijalni = 'PutniRacunKomercijalni',
  PutniRacunSt = 'PutniRacunSt',
  PutniRacunVirman = 'PutniRacunVirman',
  PutniRacunPutniNalog = 'PutniRacunPutniNalog',
  PutniRacunVrednostObracuna = 'PutniRacunVrednostObracuna',
  RacOrgSvrhaDozVirman = 'RacOrgSvrhaDozVirman',
  RacunOrganizacije = 'RacunOrganizacije',
  RacunOrganizacijeRadnik = 'RacunOrganizacijeRadnik',
  RacunUsluga1 = 'RacunUsluga1',
  RacunUslugaSt1 = 'RacunUslugaSt1',
  Radnik = 'Radnik',
  RadniNalogPro = 'RadniNalogPro',
  RashodOS = 'RashodOS',
  RashodOSSt = 'RashodOSSt',
  Region = 'Region',
  Register = 'Register',
  Republic = 'Republic',
  Republika = 'Republika',
  RetailOrder = 'RetailOrder',
  RetailStore = 'RetailStore',
  RetailSettings = 'RetailSettings',
  RezultatAnaliza = 'RezultatAnaliza',
  Roba1 = 'Roba1',
  RobaKodPartnera = 'RobaKodPartnera',
  RobaPakovanje = 'RobaPakovanje',
  RobaUsluga = 'RobaUsluga',
  RobaUOrganizaciji = 'RobaUOrganizaciji',
  RobnaGrupa = 'RobnaGrupa',
  RobnaGrupaLokacijaMagacina = 'RobnaGrupaLokacijaMagacina',
  RobaGrupaKarakteristika = 'RobaGrupaKarakteristika',
  Role = 'Role',
  RucniNalog = 'RucniNalog',
  RucniNalogSt = 'RucniNalogSt',
  SemaZaKnjizenjePDV = 'SemaZaKnjizenjePDV',
  Serija = 'Serija',
  SerijaRobe = 'SerijaRobe',
  SerijaStatus = 'SerijaStatus',
  SmartReport = 'SmartReport',
  SmartReportRole = 'SmartReportRole',
  SmartReportUser = 'SmartReportUser',
  SpisakKompletaOS = 'SpisakKompletaOS',
  StandardniTekstovi = 'StandardniTekstovi',
  StandardniTekstoviDokumenta = 'StandardniTekstoviDokumenta',
  StandardnoKnjizenjeRobe = 'StandardnoKnjizenjeRobe',
  StandardnoKnjizenjeKnjiznogPisma = 'StandardnoKnjizenjeKnjiznogPisma',
  StatusDokumenta = 'StatusDokumenta',
  StavkaNaloga = 'StavkaNaloga',
  StampeGrupneObradeFaktura = 'StampeGrupneObradeFaktura',
  StrategijskaPoslovnaJedinica = 'StrategijskaPoslovnaJedinica',
  SvrhaDoznakeZaVirman = 'SvrhaDoznakeZaVirman',
  TarifaPoreza = 'TarifaPoreza',
  TarifnaGrupaPoreza = 'TarifnaGrupaPoreza',
  TarifaPorezaKontoPdvTarifePoreza = 'TarifaPorezaKontoPdvTarifePoreza',
  TaxArea = 'TaxArea',
  TaxExemption = 'TaxExemption',
  TaxRate = 'TaxRate',
  TipAutomatskogZatvaranja = 'TipAutomatskogZatvaranja',
  TipNaloga = 'TipNaloga',
  TipKarakteristike = 'TipKarakteristike',
  TipZoneMagacina = 'TipZoneMagacina',
  Transfer = 'Transfer',
  TypeOfBusiness = 'TypeOfBusiness',
  QualityReport = 'QualityReport',
  UcitajElektronskiIzvodDialog = 'UcitajElektronskiIzvodDialog',
  UcitajIzlazneAvanseDialog = 'UcitajIzlazneAvanseDialog',
  UcitajIzlazneUplateDialog = 'UcitajIzlazneUplateDialog',
  UgovorONabavci1 = 'UgovorONabavci1',
  UlazNabavke = 'UlazNabavke',
  UlazNabavkeSt = 'UlazNabavkeSt',
  UlazneAvansneUplate = 'UlazneAvansneUplate',
  UlazniAvansniRacun = 'UlazniAvansniRacun',
  UlazniAvansniRacunSt = 'UlazniAvansniRacunSt',
  UlazniDokumenti = 'UlazniDokumenti',
  UlazniDokumentiSt = 'UlazniDokumentiSt',
  UlazniRacun1 = 'UlazniRacun1',
  UlazniRacunOS = 'UlazniRacunOS',
  UlazniRacunUslugaStRazgranicenja = 'UlazniRacunUslugaStRazgranicenja',
  UlazniRacunUslugaVirmani = 'UlazniRacunUslugaVirmani',
  UnitOfMeasure = 'UnitOfMeasure',
  UplatnicaBlagajne = 'UplatnicaBlagajne',
  UplatnicaBlagajneSt = 'UplatnicaBlagajneSt',
  User = 'User',
  UserActivity = 'UserActivity',
  UserGroup = 'UserGroup',
  UslovPlacanja = 'UslovPlacanja',
  Vaga = 'Vaga',
  VagarskaPotvrda = 'VagarskaPotvrda',
  VagarskaPotvrdaObracun = 'VagarskaPotvrdaObracun',
  Valuta = 'Valuta',
  Virman = 'Virman',
  VirmanPotpisnici = 'VirmanPotpisnici',
  Voucher = 'Voucher',
  VrednostObracuna = 'VrednostObracuna',
  VrstaAnalitike = 'VrstaAnalitike',
  VrstaAnalitikeNaKontu = 'VrstaAnalitikeNaKontu',
  VrstaDokSvrhaDozVirman = 'VrstaDokSvrhaDozVirman',
  VrstaDokumenta = 'VrstaDokumenta',
  VrstaDokumentaVeza = 'VrstaDokumentaVeza',
  VrstaIsplateSluzbenaPutovanja = 'VrstaIsplateSluzbenaPutovanja',
  VrstaIzlaznihUsluga = 'VrstaIzlaznihUsluga',
  VrstaKarticeZaBanku = 'VrstaKarticeZaBanku',
  VrstaKnjizenjaKursnihRazlika = 'VrstaKnjizenjaKursnihRazlika',
  VrstaKnjizenjaKursnihRazlikaSt = 'VrstaKnjizenjaKursnihRazlikaSt',
  VrstaKnjizenjaZaOS = 'VrstaKnjizenjaZaOS',
  VrstaKontaZaOS = 'VrstaKontaZaOS',
  VrstaMerenja = 'VrstaMerenja',
  VrstaNosiocaTroska = 'VrstaNosiocaTroska',
  VrstaObSvrhaDozVirman = 'VrstaObSvrhaDozVirman',
  VrstaObaveza = 'VrstaObaveza',
  VrstaOS = 'VrstaOS',
  VrstaPDVKontaPoreza = 'VrstaPDVKontaPoreza',
  VrstaPracenjaSerije = 'VrstaPracenjaSerije',
  VrstaPracenjaSerijeVrstaDokumenta = 'VrstaPracenjaSerijeVrstaDokumenta',
  VrstaTroska1 = 'VrstaTroska1',
  VrstaTroska2 = 'VrstaTroska2',
  VrstaUlaznoIzlaznihDokumenata = 'VrstaUlaznoIzlaznihDokumenata',
  Warehouse = 'Warehouse',
  WarehouseType = 'WarehouseType',
  ZahtevZaSluzbenoPutovanjeMestoPutovanja = 'ZahtevZaSluzbenoPutovanjeMestoPutovanja',
  ZakljucniListPeriodicniSt = 'ZakljucniListPeriodicniSt',
  ZakljucniListPeriodicniStSummary = 'ZakljucniListPeriodicniStSummary',
  ZatvaranjeRazgranicenja = 'ZatvaranjeRazgranicenja',
  ZatvaranjeRazgranicenjaSt = 'ZatvaranjeRazgranicenjaSt',
  ZonaMagacina = 'ZonaMagacina',
  ZaduzenjeOS = 'ZaduzenjeOS',
  ZaduzenjeOSSt = 'ZaduzenjeOSSt',
  RadnaListaProAlatnica2 = 'RadnaListaProAlatnica2',
  RadnaListaProAlatnica2St = 'RadnaListaProAlatnica2St',
  RadnaListaProGreske = 'RadnaListaProGreske2',
  GridIstorijaGresaka = 'GridIstorijaGresaka',
  GridPolufabrikat = 'GridPolufabrikat',
  KarticaProizvodnje = 'KarticaProizvodnje',
  DocumentDefinition = 'DocumentDefinition',
  DocumentKind = 'DocumentKind',
}

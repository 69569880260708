import { Injectable } from '@angular/core';
import { ObjectRestService } from '@base/modules/rest/object/object-rest.service';
import { HttpClient } from '@angular/common/http';
import { OrganizationRestService } from '@base/modules/rest/organization/organization-rest.service';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { Observable, of } from 'rxjs';
import { ObjectDefinitionModel } from '@base/modules/rest/bbs-object/model/object-definition.model';
import { ObjectFindRequestModel } from '@base/modules/rest/object/model/object-find-request.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable({
  providedIn: 'root',
})
export class OrganizationRestAdapterService extends ObjectRestService {

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration,
              private organizationRestService: OrganizationRestService) {
    super(httpClient, appConfiguration, ObjectNameEnum.Organization, null);
  }

  getDefinition(): Observable<ObjectDefinitionModel> {
    return of({
      name: ObjectNameEnum.Organization,
      mainField: 'name',
      columns: ['code', 'name', 'address', 'email', 'vat'],
      fields: [],
    } as ObjectDefinitionModel);
  }

  find(data: ObjectFindRequestModel): Observable<PaginationResponseModel<any>> {
    return this.organizationRestService.findAllForOverview({
      pagination: {
        ...data.page,
        sort: data.page.sort ?? [],
        filter: data.filter,
      },
      includeInactive: true,
    });
  }

  findOne(payload: { id: number }): Observable<any> {
    return this.organizationRestService.findOne(payload);
  }

  create(payload: { item: any }): Observable<any> {
    return this.organizationRestService.create({organization: payload.item});
  }

  update(payload: { id: number; item: any }): Observable<any> {
    return this.organizationRestService.update({id: payload.id, organization: payload.item});
  }

  delete(payload: { id: number }): Observable<void> {
    throw new Error('Method not implemented');
  }
}

import { CustomFieldOptionModel } from '@base/modules/rest/custom-field/model/custom-field-option.model';
import { NumerationTypeEnum } from '@base/modules/rest/custom-field/enums/custom-field-numeration-type.enum';
import { CustomFieldTypesEnum } from '../enums/custom-field-types.enum';
import { BbsObjectModel } from '../../bbs-object/model/bbs-object.model';
import { ObjectDefinitionModel } from '../../bbs-object/model/object-definition.model';
import { ObjectCompositeFilterModel } from '../../object/model/object-composite-filter.model';
import { capitalizePath, pathGetLast, pathSkipLast } from '../../../../utils/common.util';

export interface CustomFieldModel<DEFAULT_VALUE = any> {
  id?: number;
  name: string;
  label: string;
  description?: string;
  tooltip?: string;
  prefix?: string;
  suffix?: string;
  type: CustomFieldTypesEnum;
  mandatory?: boolean;
  regex?: string;
  regexErrorMessage?: string;
  placeholder?: string;
  defaultValue?: DEFAULT_VALUE;
  currentDate?: boolean;
  options?: CustomFieldOptionModel[];
  origin: CustomFieldModel;
  sequence?: number;
  dbName?: string;
  path?: string;
  columnDisplayOrder?: number;
  columnWidth?: number;
  translated?: boolean;
  visible?: boolean;
  numerationType?: NumerationTypeEnum;
  numberOfDigits?: number;
  initialNumber?: number;
  system?: boolean;
  readOnly?: boolean;
  lookupObject?: BbsObjectModel | ObjectDefinitionModel;
  lookupViewField?: string;
  filterable?: boolean;
  sortable?: boolean;
  numberOfDecimals?: number;
  defaultFilter?: boolean;
  filterOperatorVisible?: boolean;
  filterPartName?: string;
  filterValuePath?: string;
  filters?: ObjectCompositeFilterModel;
  disableFilterOperator?: boolean;
  tableCellColor?: string;
  maxLength: number;
  preventNegativeNumbers?: boolean;
  minNumber?: number;
  maxNumber?: number;
  allowTextAsValue?: boolean;
}

export function createField(payload: Partial<CustomFieldModel>): CustomFieldModel {
  const field: CustomFieldModel = {...payload} as CustomFieldModel;
  field.system = payload.system != null ? payload.system : true;
  field.origin = field;
  return field;
}

export function createObjectField(objectName: string, fieldName: string): CustomFieldModel {
  const fieldNamePath = pathSkipLast(fieldName);
  let path = fieldNamePath.length !== 0 ? `${objectName}.${fieldNamePath}` : objectName;
  path = capitalizePath(path);
  return {
    path: path,
    dbName: pathGetLast(fieldName),
    visible: true,
    filterable: true,
  } as CustomFieldModel;
}

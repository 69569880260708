import { ApplicationModel } from '@base/modules/rest/application/model/application.model';
import { CorePreloadActions } from '@base/store/preload';
import { createReducer, on } from '@ngrx/store';
import { ParametersModel } from '../../modules/rest/parameters/model/parameters.model';

export interface State {
  applications: ApplicationModel[];
  parameters: ParametersModel;
}

const initialState: State = {
  applications: [],
  parameters: null,
};

export const reducer = createReducer(
  initialState,
  on(CorePreloadActions.LoadAppPublicDataSuccess, (state: State, {parameters}): State => {
    return {
      ...state,
      parameters: parameters,
    };
  }),
  on(CorePreloadActions.LoadAppSecuredDataSuccess, (state: State, {applications, parameters}): State => {
    return {
      ...updateApplicationsState(state, applications),
      parameters: parameters,
    };
  }),
  on(CorePreloadActions.ReloadApplicationsSuccess, (state: State, {applications}): State => {
    return updateApplicationsState(state, applications);
  })
);

const updateApplicationsState = (state: State, applications: ApplicationModel[]): State => {
  return {
    ...state,
    applications,
  };
};

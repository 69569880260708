import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextareaComponent } from '@shared/components/form-elements/input-textarea/input-textarea.component';
import { AngularMaterialModule } from '@shared/external-modules/angular-material.module';
import { BuiTooltipIconModule } from '@shared/modules/bui/bui-tooltip-icon/bui-tooltip-icon.module';
import { OptionalFieldTranslatePipeModule } from '@shared/pipes/optional-field-translate.pipe';

@NgModule({
  imports: [
    AngularMaterialModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    OptionalFieldTranslatePipeModule,
    BuiTooltipIconModule,
  ],
  exports: [
    InputTextareaComponent,
  ],
  declarations: [
    InputTextareaComponent,
  ],
})
export class InputTextareaModule {
}

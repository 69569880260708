import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDialogTemplateSearchableSubheader]',
})
export class DialogTemplateSearchableSubheaderDirective {

  constructor(public tplRef: TemplateRef<any>) {}

}

import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-bui-spinner',
  templateUrl: './bui-spinner.component.html',
  styleUrls: ['./bui-spinner.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class BuiSpinnerComponent implements OnInit {
  @Input() spinnerSize: 'icon-normal' | 'xs' | 's' | 'm' | 'large' = 'm';
  @Input() spinnerType: 'page' | 'content' | 'inline' = 'page';
  @Input() spinnerAction = false;
  @Input() spinnerHasOverlay = true;
  @Output() cancelEvent = new EventEmitter<void>();
  @HostBinding('class.c-bui-spinner__wrapper--type-inline') isSpinnerTypeInline = false;
  spinnerCardType: string;

  ngOnInit(): void {
    this.isSpinnerTypeInline = this.spinnerType === 'inline';

    if (this.spinnerAction && this.spinnerType !== 'inline') {
      this.spinnerCardType = this.spinnerType as string;
      this.spinnerType = 'inline';
    }
  }

  cancel(): void {
    this.cancelEvent.emit();
  }
}

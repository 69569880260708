import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@base/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsComponent } from 'app/base/modules/breadcrumbs/component/breadcrumbs.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    PipesModule,
    MatTooltipModule,
  ],
  exports: [
    BreadcrumbsComponent,
  ],
  declarations: [
    BreadcrumbsComponent,
  ],
})
export class BreadcrumbsModule {
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  BUI_ICON_TYPE_CUSTOM,
  BUI_ICON_TYPE_DEFAULT,
  BuiIcon,
  isBuiIconMaterialIconType
} from '@base/utils/icon-types.util';

@Component({
  selector: 'app-bui-button-icon',
  templateUrl: './bui-button-icon.component.html',
  styleUrls: ['./bui-button-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuiButtonIconComponent implements OnInit {
  @Input() iconName: string;
  @Input() iconType: BuiIcon = BUI_ICON_TYPE_DEFAULT;
  @Input() iconSize: 'default' | 'tiny' | 'small' | 'medium' | 'large' = 'default';
  @Input() backgroundStyle: 'primary' | 'dark' | 'medium' | 'accent' | 'light' | 'transparent' | 'success' | 'light-green' | 'tertiary' | 'gradient-1';
  @Input() borderStyle: 'intense' | 'dark' | 'medium' | 'light' | 'transparent';
  @Input() contentColor: 'primary' | 'dark' | 'medium' | 'accent' | 'link' | 'light' | 'success' | 'green' | 'error' | 'info' | 'tertiary' | 'orange';
  @Input() hasDropdown = false;
  @Input() size: 'small' | 'normal' = 'normal';
  @Input() dropdownCaretColor: 'dark' | 'medium' | 'light';
  @Input() comboStyle: 'fill-primary' | 'fill-accent' | 'fill-gradient-1' | 'settings' | 'outline' | 'naked-dark' | 'naked-medium' | 'naked-primary' | 'naked-accent' | 'naked-inverted' | 'naked-link';
  @Input() disabled = false;
  @Input() isActive = false;
  @Input() tabIndex: number;
  @Input() hasDropdownEvent = false;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() hasDropdownClickEvent: EventEmitter<any> = new EventEmitter<any>();

  protected readonly isBuiIconMaterialIconType = isBuiIconMaterialIconType;
  protected readonly BUI_ICON_TYPE_CUSTOM = BUI_ICON_TYPE_CUSTOM;

  ngOnInit(): void {
    if (this.comboStyle === 'fill-primary') {
      this.backgroundStyle = this.backgroundStyle || 'primary';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentColor = this.contentColor || 'light';
      this.dropdownCaretColor = this.dropdownCaretColor || 'light';
    }
    if (this.comboStyle === 'fill-accent') {
      this.backgroundStyle = this.backgroundStyle || 'accent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentColor = this.contentColor || 'light';
      this.dropdownCaretColor = this.dropdownCaretColor || 'light';
    }
    if (this.comboStyle === 'fill-gradient-1') {
      this.backgroundStyle = this.backgroundStyle || 'gradient-1';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentColor = this.contentColor || 'light';
      this.dropdownCaretColor = this.dropdownCaretColor || 'light';
    }
    if (this.comboStyle === 'settings') {
      this.backgroundStyle = this.backgroundStyle || 'medium';
      this.borderStyle = this.borderStyle || 'medium';
      this.contentColor = this.contentColor || 'medium';
      this.dropdownCaretColor = this.dropdownCaretColor || 'medium';
    }
    if (this.comboStyle === 'outline') {
      this.backgroundStyle = this.backgroundStyle || 'light';
      this.borderStyle = this.borderStyle || 'intense';
      this.contentColor = this.contentColor || 'medium';
      this.dropdownCaretColor = this.dropdownCaretColor || 'medium';
    }
    if (this.comboStyle === 'naked-dark') {
      this.backgroundStyle = this.backgroundStyle || 'transparent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentColor = this.contentColor || 'dark';
      this.dropdownCaretColor = this.dropdownCaretColor || 'dark';
    }
    if (this.comboStyle === 'naked-medium') {
      this.backgroundStyle = this.backgroundStyle || 'transparent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentColor = this.contentColor || 'medium';
      this.dropdownCaretColor = this.dropdownCaretColor || 'dark';
    }
    if (this.comboStyle === 'naked-primary') {
      this.backgroundStyle = this.backgroundStyle || 'transparent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentColor = this.contentColor || 'primary';
      this.dropdownCaretColor = this.dropdownCaretColor || 'dark';
    }
    if (this.comboStyle === 'naked-accent') {
      this.backgroundStyle = this.backgroundStyle || 'transparent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentColor = this.contentColor || 'accent';
      this.dropdownCaretColor = this.dropdownCaretColor || 'dark';
    }
    if (this.comboStyle === 'naked-inverted') {
      this.backgroundStyle = this.backgroundStyle || 'transparent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentColor = this.contentColor || 'light';
      this.dropdownCaretColor = this.dropdownCaretColor || 'light';
    }
    if (this.comboStyle === 'naked-link') {
      this.backgroundStyle = this.backgroundStyle || 'transparent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentColor = this.contentColor || 'link';
      this.dropdownCaretColor = this.dropdownCaretColor || 'dark';
    }
  }

  onClick(event: any): void {
    if (!this.disabled) {
      this.clickEvent.emit(event);
    }
  }
}

import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appLayoutContentMainHeaderActiveFilters]',
})
export class LayoutContentMainHeaderActiveFiltersDirective {

  constructor(public tplRef: TemplateRef<any>) { }

}

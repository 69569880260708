import { Directive, NgModule, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appValidationError]',
})
export class ValidationErrorDirective {

  constructor(public tmpRef: TemplateRef<any>) {
  }
}

@NgModule({
  exports: [
    ValidationErrorDirective,
  ],
  declarations: [
    ValidationErrorDirective,
  ],
})
export class ValidationErrorModule {
}

import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDialogTemplateFooterActionsSecond]',
})
export class DialogTemplateFooterActionsSecondDirective {

  constructor(public tplRef: TemplateRef<any>) {
  }

}

<div class="c-dialog-template-pager"
  [appBreakpointObserver]="mqBelowNarrow"
  (observedBreakpointChanged)="isScreenSizeSmall = $event">
  <div class="c-dialog-template-pager__action-previous">
    <app-bui-button-icon
      [iconName]="'chevron-left'"
      [comboStyle]="'naked-medium'"
      [disabled]="currentPage === 1 || disabled"
      (clickEvent)="previousPageEvent.emit($event)">
    </app-bui-button-icon>
  </div>
  <div class="c-dialog-template-pager__info">
    <ng-container *ngIf="!isScreenSizeSmall">{{ 'DIALOG_TEMPLATE_PAGER.STEP' | translate }}</ng-container> <span class="c-dialog-template-pager__info-current">{{ currentPage }}</span> {{ 'DIALOG_TEMPLATE_PAGER.OF' | translate }} <span class="c-dialog-template-pager__info-total">{{ totalPages }}</span>
  </div>
  <div class="c-dialog-template-pager__action-next">
    <app-bui-button-icon
      [iconName]="'chevron-right'"
      [comboStyle]="'naked-medium'"
      [disabled]="currentPage === totalPages || disabled"
      (clickEvent)="nextPageEvent.emit($event)">
    </app-bui-button-icon>
  </div>
</div>

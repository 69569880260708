<div class="c-dialog-template-header-actions">
  <ng-content></ng-content>
  <ng-container *ngIf="showClose">
    <app-bui-button-icon
      [disabled]="isDisabled"
      [iconName]="'close'"
      [comboStyle]="'naked-medium'"
      (clickEvent)="close()">
    </app-bui-button-icon>
  </ng-container>
</div>

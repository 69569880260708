<button
  type="button"
  class="c-bui-button-icon"
  [ngClass]="[
    backgroundStyle ? 'c-bui-button-icon--background-' + backgroundStyle : '',
    borderStyle ? 'c-bui-button-icon--border-' + borderStyle : '',
    hasDropdown ? 'c-bui-button-icon--has-dropdown' : '',
    size ? 'c-bui-button-icon--size-' + size : '',
    isActive ? 'c-bui-button-icon--is-active' : '',
    disabled ? 'u-disabled' : '',
  ]"
  matRipple
  [matRippleUnbounded]="comboStyle === ('naked-dark' || 'naked-medium' || 'naked-accent' || 'naked-link')"
  [matRippleCentered]="comboStyle === ('naked-dark' || 'naked-medium' || 'naked-accent' || 'naked-link')"
  [attr.mat-icon-button]="(isBuiIconMaterialIconType | wrapFn: iconType)"
  [matRippleDisabled]="true"
  [disabled]="disabled"
  [tabindex]="disabled ? '-1' : tabIndex"
  (click)="onClick($event)">

  <div class="c-bui-button-icon__content"
    [ngClass]="[
      contentColor ? 'c-bui-button-icon__content--color-' + contentColor : '',
    ]">
    <ng-container *ngIf="iconType === BUI_ICON_TYPE_CUSTOM">
      <app-svg-icon [icon]="iconName"></app-svg-icon>
    </ng-container>
    <ng-container *ngIf="(isBuiIconMaterialIconType | wrapFn: iconType)">
      <mat-icon
        [ngClass]="[
          iconSize ? 'c-bui-button-icon--icon-size-' + iconSize : '',
        ]"
        [svgIcon]="iconType + ':' + iconName">
      </mat-icon>
    </ng-container>
  </div>

  <ng-container *ngIf="hasDropdown">
    <div class="c-bui-button-icon__dropdown-caret"
      [ngClass]="[
        dropdownCaretColor ? 'c-bui-button-icon__dropdown-caret--color-' + dropdownCaretColor : '',
      ]">
      <mat-icon [svgIcon]="'mdi:menu-down'"></mat-icon>
    </div>
  </ng-container>

</button>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '@base/pipes/pipes.module';
import { SvgIconModule } from '@shared/components/svg-icon/svg-icon.module';
import { BuiIconComponent } from './bui-icon.component';
import { BuiIconContainerComponent } from './components/bui-icon-container/bui-icon-container.component';
import { WrapFnPipeModule } from '@base/pipes/wrap-fn.pipe';

@NgModule({
  declarations: [
    BuiIconComponent,
    BuiIconContainerComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    SvgIconModule,
    PipesModule,
    WrapFnPipeModule,
  ],
  exports: [
    BuiIconComponent,
    BuiIconContainerComponent,
  ],
})
export class BuiIconModule { }

import { Component, ContentChild, ElementRef, Input, ViewChild } from '@angular/core';
import {
  DialogTemplateCloseService
} from '@shared/components/templates/dialog-template/services/dialog-template-close.service';
import { DialogTemplateFooterComponent } from './dialog-template-footer/dialog-template-footer.component';

@Component({
  selector: 'app-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: ['./dialog-template.component.scss'],
  providers: [
    DialogTemplateCloseService,
  ],
})
export class DialogTemplateComponent {
  @Input() hasHeaderFooterGap: boolean;
  @Input() bodyHasNegativeSpaceX = false;
  @ViewChild('dialogTemplateBodyWrapper') dialogTemplateBodyWrapper: ElementRef;

  @ContentChild(DialogTemplateFooterComponent) footer: DialogTemplateFooterComponent;

}

<div class="c-layout-content-main"
  [ngClass]="[
    viewAsPage ? 'c-layout-content-main--view-as-page' : '',
    fullScreenMode ? 'c-layout-content-main--without-header' : 'c-layout-content-main--with-header',
  ]">
  <div class="c-layout-content-main__header"
    [ngClass]="fullScreenMode ? 'c-layout-content-main__header--invisible' : 'c-layout-content-main__header--visible'">
    <div class="c-layout-content-main__header-primary"
      [ngClass]="headerHorizontal ? 'c-layout-content-main__header-primary--horizontal' : 'c-layout-content-main__header-primary--vertical'"
      *ngIf="!!headerTitleDirective || showBreadcrumbs || !!headerActionsDirective">
      <div class="c-layout-content-main__header-primary-first" *ngIf="!!headerTitleDirective || showBreadcrumbs">
        <div class="c-layout-content-main__header-breadcrumbs" *ngIf="showBreadcrumbs">
          <app-breadcrumbs></app-breadcrumbs>
        </div>
        <div class="c-layout-content-main__header-title" *ngIf="!!headerTitleDirective">
          <ng-container [ngTemplateOutlet]="headerTitleDirective.tplRef"></ng-container>
        </div>
      </div>
      <div class="c-layout-content-main__header-primary-second">
        <div class="c-layout-content-main__header-actions" *ngIf="!!headerActionsDirective">
          <ng-container [ngTemplateOutlet]="headerActionsDirective.tplRef"></ng-container>
        </div>
      </div>
    </div>
    <div class="c-layout-content-main__header-secondary"
      [ngClass]="headerHorizontal ? 'c-layout-content-main__header-secondary--horizontal' : 'c-layout-content-main__header-secondary--vertical'"
      *ngIf="!!headerViewTemplatesDirective || !!headerFiltersSearchDirective || !!headerFiltersActionsDirective">
      <div class="c-layout-content-main__header-secondary-first" *ngIf="!!headerViewTemplatesDirective">
        <ng-container [ngTemplateOutlet]="headerViewTemplatesDirective.tplRef"></ng-container>
      </div>
      <div class="c-layout-content-main__header-secondary-second"
        *ngIf="!!headerFiltersSearchDirective || !!headerFiltersActionsDirective">
        <div class="c-layout-content-main__header-filters">
          <div class="c-layout-content-main__header-filters-search" *ngIf="!!headerFiltersSearchDirective">
            <ng-container [ngTemplateOutlet]="headerFiltersSearchDirective.tplRef"></ng-container>
          </div>
          <div class="c-layout-content-main__header-filters-actions" *ngIf="!!headerFiltersActionsDirective">
            <ng-container [ngTemplateOutlet]="headerFiltersActionsDirective.tplRef"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="c-layout-content-main__header-bottom" *ngIf="!!headerActiveFiltersDirective">
      <div class="c-layout-content-main__header-active-filters">
        <ng-container [ngTemplateOutlet]="headerActiveFiltersDirective.tplRef"></ng-container>
      </div>
    </div>
  </div>
  <div class="c-layout-content-main__body u-scrollable-y">
    <ng-container [ngTemplateOutlet]="bodyDirective.tplRef"></ng-container>
  </div>
</div>

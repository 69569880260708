import { Injectable } from '@angular/core';
import { ObjectRestService } from '@base/modules/rest/object/object-rest.service';
import { HttpClient } from '@angular/common/http';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { Observable } from 'rxjs';
import { ObjectFindRequestModel } from '@base/modules/rest/object/model/object-find-request.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { map } from 'rxjs/operators';
import { AppConfiguration } from '../../../../app-configuration';
import { SmartReportRestService } from './smart-report-rest.service';

@Injectable({
  providedIn: 'root',
})
export class SmartReportRestAdapterService extends ObjectRestService {

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration,
              private smartReportRestService: SmartReportRestService) {
    super(httpClient, appConfiguration, ObjectNameEnum.SmartReport, null);
  }

  find(data: ObjectFindRequestModel): Observable<PaginationResponseModel<any>> {
    return this.smartReportRestService.find({
      pagination: {
        page: data.page.page,
        size: data.page.size,
        sort: ['id,desc'],
        filter: data.filter,
      },
      reportView: data.params['reportView'],
    });
  }

  findOne(payload: { id: number }): Observable<any> {
    return this.smartReportRestService.getOne(payload);
  }

  create(payload: { item: any }): Observable<any> {
    return this.smartReportRestService.create(payload.item);
  }

  update(payload: { id: number; item: any }): Observable<any> {
    return this.smartReportRestService.update(payload.item);
  }

  delete(payload: { id: number }): Observable<void> {
    return this.smartReportRestService.deleteReport(payload.id)
      .pipe(map(() => null));
  }
}

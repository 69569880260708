import { Component, OnInit } from '@angular/core';
import { ActuatorRestService } from '@base/modules/rest/actuator/actuator-rest.service';
import { TranslationService } from '@base/services/translation.service';
import { AppState } from '@base/store';
import { CoreUiSelectors } from '@base/store/ui';
import { Store } from '@ngrx/store';
import { BeyondSuitePageGuard } from '@views/secured/beyond-suite/guard/beyond-suite-page.guard';
import { Observable } from 'rxjs';
import { ERROR_STATE_MATCHER_PROVIDER } from '@shared/external-modules/angular-material.module';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { CanCloseAppService } from '@base/services/can-close-app.service';
import { setAppVersion } from './version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    ERROR_STATE_MATCHER_PROVIDER,
  ],
})
export class AppComponent implements OnInit {
  loadingData$: Observable<boolean> = this.store.select(CoreUiSelectors.loadingData);
  homePageLoading$: Observable<boolean> = this.beyondSuitePageGuard.loading$;
  isTouchDevice: boolean;
  isLoading = true;

  /*
    Not used injected services in constructor are purposely there
    Angular will not create singleton instance of service until it is used for the first time
    https://github.com/angular/angular/issues/25633
   */
  constructor(private store: Store<AppState>,
              private translationService: TranslationService,
              private actuatorRestService: ActuatorRestService,
              private beyondSuitePageGuard: BeyondSuitePageGuard,
              private router: Router,
              private canCloseAppService: CanCloseAppService) {
  }

  ngOnInit(): void {
    this.actuatorRestService.info()
      .subscribe(info => setAppVersion(info?.version));

    // Detect touch device.
    this.isTouchDevice = 'ontouchstart' in window;

    // Set helper class to `<html>` element to use if is touch device.
    if (this.isTouchDevice) {
      const rootElement = document.documentElement;
      rootElement.classList.add('is-touch-device');
    }

    this.hideInitialLoader();
    this.listenForClose();
  }

  private hideInitialLoader(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError),
        take(1)
      )
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  private listenForClose(): void {
    window.addEventListener('beforeunload', (event) => {
      const canClose = this.canCloseAppService.canClose();
      if (canClose) {
        return;
      }
      event.preventDefault();
      if (event) {
        event.returnValue = true;
      }
    });
  }
}

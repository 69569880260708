import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DialogTemplateModule } from '../../templates/dialog-template/dialog-template.module';
import { BuiButtonLabelModule } from '../../../modules/bui/bui-button-label/bui-button-label.module';
import { BuiButtonIconModule } from '../../../modules/bui/bui-button-icon/bui-button-icon.module';
import { InputTextareaModule } from '../../form-elements/input-textarea/input-textarea.module';
import { ApplicationErrorDetailsDialogComponent } from './application-error-details-dialog.component';

@NgModule({
  imports: [
    DialogTemplateModule,
    TranslateModule,
    BuiButtonLabelModule,
    BuiButtonIconModule,
    InputTextareaModule,
    InputTextareaModule,
    ReactiveFormsModule,
    MatTooltipModule,
    ClipboardModule,
  ],
  declarations: [
    ApplicationErrorDetailsDialogComponent,
  ],
  exports: [
    ApplicationErrorDetailsDialogComponent,
  ],
})
export class ApplicationErrorDetailsDialogModule {
}

import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { PermissionActionEnum } from '@base/modules/rest/permissions/enums/permission-action.enum';
import { PermissionVisibilityEnum } from '@base/modules/rest/permissions/enums/permission-visibility.enum';
import { SystemRoles } from '@base/modules/rest/user/enum/system-roles.enum';
import { CurrentUserContextResponseModel } from '@base/modules/rest/user/response/current-user-context-response.model';
import { PermissionModel } from '@base/modules/rest/permissions/model/permission.model';

export function hasPermission(userContext: CurrentUserContextResponseModel, view: ObjectNameEnum, actions: PermissionActionEnum[]): boolean {
  let filteredPermissions: PermissionModel[] = [];
  if (userContext?.permissions?.length) {
    filteredPermissions = userContext.permissions
      .filter(permission => permission.bbsObject.name === view && actions.some(action => permission?.actions.includes(action)));
  }

  return filteredPermissions.length > 0;
}

export function hasPermissionWithVisibility(userContext: CurrentUserContextResponseModel, view: ObjectNameEnum, action: PermissionActionEnum, visibility: PermissionVisibilityEnum): boolean {
  let filteredPermissions: PermissionModel[] = [];
  if (userContext?.permissions?.length) {
    filteredPermissions = userContext.permissions
      .filter(permission => permission.bbsObject.name === view && permission?.actions.includes(action) && permission.visibility === visibility);
  }

  return filteredPermissions.length > 0;
}

export function hasSystemRole(userContext: CurrentUserContextResponseModel, systemRoles: SystemRoles[]): boolean {
  return !systemRoles || systemRoles?.includes(userContext.user.systemRole);
}

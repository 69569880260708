import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import * as SockJS from 'sockjs-client';
import { AppConfiguration } from '../../../../app-configuration';

export function rxStompConfig(appConfiguration: AppConfiguration): InjectableRxStompConfig {
  return {
    webSocketFactory: () => {
      return new SockJS(appConfiguration.websocket.url);
    },
    connectHeaders: {},
    heartbeatIncoming: 0, // Typical value 0 - disabled
    heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds
    reconnectDelay: 200,
    debug: () => void 0,
  };
}

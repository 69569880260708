import { AngularMaterialModule } from '@shared/external-modules/angular-material.module';
import { BuiButtonLabelComponent } from '@shared/modules/bui/bui-button-label/bui-button-label.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    BuiButtonLabelComponent,
  ],
  imports: [
    AngularMaterialModule,
    CommonModule,
  ],
  exports: [
    BuiButtonLabelComponent,
  ],
})
export class BuiButtonLabelModule { }

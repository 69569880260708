import {
  OrganizationRegisteredResponseModel
} from '@base/modules/rest/registration/response/organization-registered-response.model';
import { StateNameEnum } from '@base/store/state-name.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './reducer';

const state_selector = createFeatureSelector<fromReducer.State>(StateNameEnum.core_organization);
export const organizations = createSelector(state_selector, (state: fromReducer.State) => state.organizations);
export const organizationRegisteredResponse = createSelector(state_selector, (state: fromReducer.State) => state.organizationRegisteredResponse);
export const hasActiveLicense = createSelector(state_selector, (state: fromReducer.State) => state.hasActiveLicense);
export const aktivnaOrganizacija = createSelector(state_selector, (state: fromReducer.State) => state.aktivnaOrganizacija);
export const spj = createSelector(state_selector, (state: fromReducer.State) => state.spj);
export const poslovnaGodina = createSelector(state_selector, (state: fromReducer.State) => state.poslovnaGodina);
export const organizacionaJedinica = createSelector(state_selector, (state: fromReducer.State) => state.organizacionaJedinica);

export const organizationRegistered = createSelector(
  organizationRegisteredResponse,
  (value: OrganizationRegisteredResponseModel) => value && value.organizationRegistered && value.organizationRegistrationConfirmed
);

export const organizationRegistrationStarted = createSelector(
  organizationRegisteredResponse,
  (value: OrganizationRegisteredResponseModel) => value && value.organizationRegistered && !value.organizationRegistrationConfirmed
);

import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appLayoutContentMainHeaderTitle]',
})
export class LayoutContentMainHeaderTitleDirective {

  constructor(public tplRef: TemplateRef<any>) { }

}

import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { BreadcrumbModel } from '@base/modules/breadcrumbs/model/breadcrumb.model';
import { BREADCRUMBS_DATA, BreadcrumbsPostProcessor } from '@base/modules/breadcrumbs/model/breadcrumb.util';
import { BehaviorSubject, combineLatest, concat, Observable, of, Subscription } from 'rxjs';
import { concatMap, distinct, filter, first, map, mergeMap, tap, toArray } from 'rxjs/operators';

@Injectable()
export class BreadcrumbsService implements OnDestroy {
  private _breadcrumbs$ = new BehaviorSubject<BreadcrumbModel[]>([]);
  private _replaceValuesMap$ = new BehaviorSubject(new Map<string, any>());
  private _postProcessorTrigger$ = new BehaviorSubject<void>(null);

  private postProcessor: BreadcrumbsPostProcessor;

  private routeEventsSubscription: Subscription;
  private navigationEndSubscription: Subscription;

  constructor(private router: Router) {
    this.routeEventsSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        concatMap(() => this.onNavigationEnd())
      )
      .subscribe();

    this.navigationEndSubscription = this.onNavigationEnd()
      .subscribe();
  }

  ngOnDestroy(): void {
    this.routeEventsSubscription.unsubscribe();
    this.navigationEndSubscription.unsubscribe();
  }

  replaceValue(key: string, value: any): void {
    const currentMap = this._replaceValuesMap$.value;
    currentMap.set(key, value);
    this._replaceValuesMap$.next(currentMap);
  }

  removeValue(key: string): void {
    const currentMap = this._replaceValuesMap$.value;
    currentMap.delete(key);
    this._replaceValuesMap$.next(currentMap);
  }

  setPostProcessor(postProcessor: BreadcrumbsPostProcessor): void {
    this.postProcessor = postProcessor;
  }

  triggerPostProcessing(): void {
    this._postProcessorTrigger$.next();
  }

  get breadcrumbs$(): Observable<BreadcrumbModel[]> {
    return combineLatest([
      this._breadcrumbs$.asObservable(),
      this._replaceValuesMap$.asObservable(),
      this._postProcessorTrigger$.asObservable(),
    ])
      .pipe(
        map(([breadcrumbs, replaceValuesMap]) => {
          return breadcrumbs
            .map(breadcrumb => {
              if (replaceValuesMap.has(breadcrumb.text)) {
                return {
                  ...breadcrumb,
                  text: replaceValuesMap.get(breadcrumb.text),
                };
              }
              return breadcrumb;
            });
        }),
        map((breadcrumbs: BreadcrumbModel[]) => this.postProcessor ? this.postProcessor(breadcrumbs) : breadcrumbs)
      );
  }

  private onNavigationEnd(): Observable<BreadcrumbModel[]> {
    return this.resolveCrumbs(this.router.routerState.snapshot.root)
      .pipe(
        mergeMap((breadcrumbs: BreadcrumbModel[]) => breadcrumbs),
        distinct((breadcrumb: BreadcrumbModel) => breadcrumb.text),
        toArray(),
        tap((breadcrumbs) => {
          this._breadcrumbs$.next(breadcrumbs);
        })
      );
  }

  private resolveCrumbs(route: ActivatedRouteSnapshot): Observable<BreadcrumbModel[]> {
    let crumbs$: Observable<BreadcrumbModel[]> = of([]);
    const data = route.routeConfig?.data;

    if (data?.[BREADCRUMBS_DATA]) {
      const result: BreadcrumbModel[] = this.resolve(route);
      crumbs$ = of(result).pipe(first());
    }

    return route.firstChild ? concat(crumbs$, this.resolveCrumbs(route.firstChild)) : crumbs$;
  }

  private resolve(route: ActivatedRouteSnapshot): BreadcrumbModel[] {
    const data = route.routeConfig.data;
    const path = this.getFullPath(route);
    const text = data[BREADCRUMBS_DATA].text;

    return [
      {
        text: text,
        navigate: () => this.router.navigate([path]),
      },
    ];
  }

  private getFullPath(route: ActivatedRouteSnapshot): string {
    const relativePath = (segments: UrlSegment[]) => segments.reduce((a, v) => (a + '/' + v.path), '');
    const fullPath = (routes: ActivatedRouteSnapshot[]) => routes.reduce((a, v) => (a + relativePath(v.url)), '');
    return fullPath(route.pathFromRoot);
  }
}

<div class="c-bui-spinner__overlay" *ngIf="spinnerHasOverlay"
  [ngClass]="['c-bui-spinner__overlay--type-' + spinnerType]">
</div>

<ng-container *ngIf="spinnerAction">
  <div class="c-bui-spinner__card"
    [ngClass]="[
      'c-bui-spinner__card--type-' + spinnerCardType
    ]">
    <div class="c-bui-spinner__card-header">
      <ng-template [ngTemplateOutlet]="spinner"></ng-template>
    </div>
    <div class="c-bui-spinner__card-body">
      <div class="c-bui-spinner__card-text">{{ 'BUI_SPINNER.LOADING_TEXT' | translate }}</div>
    </div>
    <div class="c-bui-spinner__card-footer">
      <button mat-stroked-button
        (click)="cancel()">
        {{ 'BUI_SPINNER.CANCEL' | translate }}
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!spinnerAction">
  <ng-template [ngTemplateOutlet]="spinner"></ng-template>
</ng-container>

<ng-template #spinner>
  <div class="c-bui-spinner"
    [ngClass]="[
      'c-bui-spinner--type-' + spinnerType,
      'c-bui-spinner--size-' + spinnerSize
    ]">
    <img src="assets/images/spinner.svg">
  </div>
</ng-template>

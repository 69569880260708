import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';

@Injectable()
export class ActuatorRestService {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/actuator';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  info(): Observable<{ version: string }> {
    return this.httpClient.get<{ version: string }>(`${this.controllerUrl}/info`);
  }
}

import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { BeyondPaginationModel } from '@base/model/common/beyond-pagination.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { PageableRestService } from '@base/modules/rest/common/pageable-rest.service';
import { ChangeActivityModel } from '@base/modules/rest/master-data/model/change-activity.model';
import { Observable } from 'rxjs';
import { MasterDataBaseModel } from './model/master-data-base.model';

export abstract class AbstractMasterDataRestService<E extends MasterDataBaseModel> implements PageableRestService<E> {

  protected constructor(protected httpClient: HttpClient,
                        protected controllerUrl: string) {
  }

  findAll(payload: { pagination: BeyondPaginationModel; params?: { [key: string]: any } }): Observable<PaginationResponseModel<E>> {
    const params = payload.params ?? {};
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter || '',
      ...params,
    };
    return this.httpClient.get<PaginationResponseModel<E>>(this.controllerUrl, {params: queryParams});
  }

  createItem(payload: { item: E }): Observable<E> {
    return this.httpClient.post<E>(this.controllerUrl, payload.item);
  }

  findOne(payload: { id: number }): Observable<E> {
    return this.httpClient.get<E>(`${this.controllerUrl}/${payload.id}`);
  }

  updateItem(payload: { item: E }): Observable<E> {
    return this.httpClient.put<E>(`${this.controllerUrl}/${payload.item.id}`, payload.item);
  }

  changeActivity(payload: { data: ChangeActivityModel }): Observable<E> {
    const id = payload.data.id;
    const data = {active: payload.data.active};
    return this.httpClient.post<any>(`${this.controllerUrl}/${id}/change-activity`, data);
  }

  delete(payload: { id: number }): Observable<void> {
    return this.httpClient.delete<void>(`${this.controllerUrl}/${payload.id}`);
  }

  isNameAvailable(payload: { name: string }): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.controllerUrl}/available-name`, `${payload.name}`);
  }

  isCodeAvailable(payload: { code: string }): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.controllerUrl}/available-code`, `${payload.code}`);
  }
}

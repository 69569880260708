import { Injectable } from '@angular/core';
import { ApplicationModel } from '@base/modules/rest/application/model/application.model';
import { LicenseRestService } from '@base/modules/rest/license/license-rest.service';
import { OrganizationMasterDataModel } from '@base/modules/rest/organization/model/organization-master-data.model';
import { OrganizationRestService } from '@base/modules/rest/organization/organization-rest.service';
import { RegistrationRestService } from '@base/modules/rest/registration/registration-rest.service';
import {
  OrganizationRegisteredResponseModel
} from '@base/modules/rest/registration/response/organization-registered-response.model';
import { CurrentUserContextResponseModel } from '@base/modules/rest/user/response/current-user-context-response.model';
import { UserRestService } from '@base/modules/rest/user/user-rest.service';
import { TranslationService } from '@base/services/translation.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of, switchMap, timeout } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { ObjectRestServiceCreator } from '@base/modules/rest/object/object-rest-service-creator';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { createFieldFilter } from '@views/secured/object/utils/composite-filters.util';
import { FilterOperationEnum } from '@shared/components/filter/enums/filter-operation.enum';
import { ParametersModel } from '../../modules/rest/parameters/model/parameters.model';
import { PaginationResponseModel } from '../../model/common/pagination-response.model';
import { StrategijskaPoslovnaJedinicaModel } from '../../modules/rest/mis4/S/strategijska-poslovna-jedinica.model';
import { OrganizacijaModel } from '../../modules/rest/mis4/O/organizacija.model';
import { PoslovnaGodinaModel } from '../../modules/rest/mis4/P/poslovna-godina.model';
import { getCurrentYear } from '../../utils/date.util';
import { ParametersRestService } from '../../modules/rest/parameters/parameters-rest.service';
import { OrganizacionaJedinicaModel } from '../../modules/rest/mis4/O/organizaciona-jedinica.model';
import { EmployeeModel } from '../../modules/rest/user/model/employee.model';
import * as fromActions from './actions';

const TIMEOUT_TIME = 20000;

@Injectable()
export class CorePreloadEffects {
  private applicationRestService = this.objectRestServiceCreator.create(ObjectNameEnum.Application);

  constructor(private actions$: Actions,
              private registrationRestService: RegistrationRestService,
              private userRestService: UserRestService,
              private licenseRestService: LicenseRestService,
              private objectRestServiceCreator: ObjectRestServiceCreator,
              private organizationRestService: OrganizationRestService,
              private parametersRestService: ParametersRestService,
              private translationService: TranslationService) {
  }

  loadAppPublicData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadAppPublicData),
      exhaustMap(() => {
        return forkJoin([
          this.parametersRestService.getAllPublic(),
          this.registrationRestService.isOrganizationRegistered(),
        ])
          .pipe(
            map(([parameters, organizationRegisteredResponse]: [ParametersModel, OrganizationRegisteredResponseModel]) =>
              fromActions.LoadAppPublicDataSuccess({
                parameters: parameters,
                organizationRegisteredResponse: organizationRegisteredResponse,
              })
            ),
            catchError(() => of(fromActions.LoadAppPublicDataFailed()))
          );
      }))
  );

  loadAppSecuredData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadAppSecuredData),
      exhaustMap(() => {

        return forkJoin([
          this.parametersRestService.getAll(),
          this.userRestService.currentUserContext()
            .pipe(catchError(() => of(null))),
          this.applicationRestService.findAll(),
          this.organizationRestService.getAll(),
          this.licenseRestService.hasActiveLicense(),
          this.loadSPJ(),
          this.loadPoslovnaGodina(),
          this.loadOrganizacionaJedinica(),
          this.loadGxtEmployeeInfo(),
        ])
          .pipe(
            switchMap(([parameters, userResponse, applications, organizations, hasActiveLicense, spj, poslovnaGodina, organizacionaJedinica, employeeInfo]:
                         [ParametersModel, CurrentUserContextResponseModel, ApplicationModel[], OrganizationMasterDataModel[], boolean, StrategijskaPoslovnaJedinicaModel, PoslovnaGodinaModel, OrganizacionaJedinicaModel, EmployeeModel]) => {
              const organizacijaRestService = this.objectRestServiceCreator.create(ObjectNameEnum.Organizacija);
              return organizacijaRestService.findOne({id: userResponse.activeOrganizationId})
                .pipe(
                  catchError(() => of(null)),
                  map((organizacija: OrganizacijaModel) => {
                    return fromActions.LoadAppSecuredDataSuccess({
                      parameters: parameters,
                      currentUserContextResponse: userResponse,
                      applications: applications,
                      organizations: organizations,
                      hasActiveLicense: hasActiveLicense,
                      aktivnaOrganizacija: organizacija,
                      spj: spj,
                      poslovnaGodina: poslovnaGodina,
                      organizacionaJedinica: organizacionaJedinica,
                      employeeInfo: employeeInfo,
                    });
                  })
                );
            })
          );
      }))
  );

  private loadSPJ(): Observable<StrategijskaPoslovnaJedinicaModel> {
    const restService = this.objectRestServiceCreator.create(ObjectNameEnum.StrategijskaPoslovnaJedinica);
    return restService.find({
      page: {page: 0, size: 10},
      compositeFilters: {
        expression: 'item1',
        filters: [createFieldFilter('item1', ObjectNameEnum.StrategijskaPoslovnaJedinica, 'status', 1, FilterOperationEnum.EQUALS)],
      },
    })
      .pipe(
        timeout(TIMEOUT_TIME),
        map((response: PaginationResponseModel<StrategijskaPoslovnaJedinicaModel>) => {
          if (response.content.length === 1) {
            return response.content[0];
          }
          return null;
        }),
        this.handleExternalServiceError()
      );
  }

  private loadPoslovnaGodina(): Observable<PoslovnaGodinaModel> {
    const userBusinessYear = localStorage.getItem('business_year') as unknown as number;
    const nowYear = getCurrentYear();
    const year = userBusinessYear || nowYear;
    return this.objectRestServiceCreator.create(ObjectNameEnum.PoslovnaGodina)
      .findOne({id: year})
      .pipe(
        timeout(TIMEOUT_TIME),
        this.handleExternalServiceError()
      );
  }

  private loadOrganizacionaJedinica(): Observable<OrganizacionaJedinicaModel> {
    return this.userRestService.getOrganizacionaJedinica()
      .pipe(
        timeout(TIMEOUT_TIME),
        this.handleExternalServiceError()
      );
  }

  private loadGxtEmployeeInfo(): Observable<EmployeeModel> {
    return this.userRestService.getGxtEmployeeInfo()
      .pipe(
        timeout(TIMEOUT_TIME),
        this.handleExternalServiceError()
      );
  }

  handleExternalServiceError(): (s: Observable<any>) => Observable<any> {
    return (source: Observable<any>) => {
      return source.pipe(
        catchError((errorResponse: any) => {
          if (errorResponse?.name === 'TimeoutError') {
            console.error('Timeout error while loading data', errorResponse);
          }
          return of(null);
        })
      );
    };
  }

  loadAppSecuredDataSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.LoadAppSecuredDataSuccess),
        tap((action) => {
          this.translationService.setLanguage(action.currentUserContextResponse.user?.language);
        })
      )
    , {dispatch: false});

  reloadApplications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.ReloadApplications),
      switchMap(() => {
        return this.applicationRestService.findAll()
          .pipe(
            map((applications: ApplicationModel[]) => fromActions.ReloadApplicationsSuccess({applications}))
          );
      })
    );
  });
}

import { KeycloakService } from 'keycloak-angular';
import { ParametersRestService } from '@base/modules/rest/parameters/parameters-rest.service';
import { KeycloakParametersModel } from '@base/modules/rest/parameters/model/keycloak-parameters.model';

export function initializer(parametersRestService: ParametersRestService, keycloak: KeycloakService): () => Promise<any> {
  return () => new Promise((resolve, reject) => {
    parametersRestService.getKeycloakParameters()
      .subscribe((keycloakParameters) => {
        if (keycloakParameters.keycloakEnabled) {
          initKeycloak(keycloak, keycloakParameters, resolve, reject);
        } else {
          resolve(true);
        }
      }, (errorResponse) => {
        if (errorResponse?.error?.errorCode === 'TENANT_NOT_DEFINED') {
          resolve(true);
        }
      });
  });
}

function initKeycloak(keycloak: KeycloakService, keycloakParameters: KeycloakParametersModel, resolve: any, reject: any): void {
  try {
    keycloak.init({
      config: {
        url: keycloakParameters.keycloakUrl,
        realm: keycloakParameters.keycloaRealm,
        clientId: keycloakParameters.keycloakWebAppClientId,
      },
      // If set a false you cannot get any information about the user example the username
      // if you use keycloakService.getUserName() you get this error
      // User not logged in or user profile was not loaded.
      loadUserProfileAtStartUp: false,
      initOptions: {
        //   This is an action we specified on keycloak load
        //   We have two options : 'login-required'|'check-sso'
        //   If is set to 'login-required' this means your browser will do a full redirect to the Keycloak server and back to your application.
        //   If is set to  'check-sso'  instead this action will be performed in a hidden iframe, so your application resources only need to be loaded and parsed once by the browser.
        //   Then you will need to add the silentCheckSsoRedirectUri and create a html file   silent-check-sso.html with this content
        // <html>
        //    <body>
        //         <script>
        //           parent.postMessage(location.href, location.origin);
        //         </script>
        //      </body>
        // </html>
        onLoad: 'check-sso',
        checkLoginIframe: true,
        // silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      // By default the keycloak-angular library add Authorization: Bearer TOKEN to all http requests
      // Then to exclude a list of URLs that should not have the authorization header we need to provide  them here.
      bearerExcludedUrls: ['/assets'],
    })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  } catch (error) {
    reject(error);
  }
}

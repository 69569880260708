import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDialogTemplateSearchableHeader]',
})
export class DialogTemplateSearchableHeaderDirective {

  constructor(public tplRef: TemplateRef<any>) {}

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dialog-template-body',
  templateUrl: './dialog-template-body.component.html',
  styleUrls: ['./dialog-template-body.component.scss'],
})
export class DialogTemplateBodyComponent {
  @Input() hasSpaceY = true;
  @Input() hasPaddingY: boolean;
}

import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { BreadcrumbModel } from '@base/modules/breadcrumbs/model/breadcrumb.model';
import { BreadcrumbsService } from '@base/modules/breadcrumbs/services/breadcrumbs.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  breadcrumbs$: Observable<BreadcrumbModel[]> = this.breadcrumbsService?.breadcrumbs$
    .pipe(
      map((breadcrumbs: BreadcrumbModel[]) => breadcrumbs.length !== 1 ? breadcrumbs : breadcrumbs.slice(0, breadcrumbs.length - 1))
    );

  constructor(@Optional() private breadcrumbsService: BreadcrumbsService) {
  }

  navigate(item: BreadcrumbModel): void {
    item.navigate();
  }
}

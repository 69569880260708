export const TENANT_HEADER = 'X-TENANT-ID';
export const TENANT_ID = 'tenant_id';

export function getTenantId(): string {
  return localStorage.getItem(TENANT_ID) || '';
}

export function setTenantId(tenantId: string): void {
  localStorage.setItem(TENANT_ID, tenantId);
}

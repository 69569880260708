import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import {
  GridTypeEnum
} from '@views/secured/beyond-core/settings/applications-settings/views/layouts/views/layout-config/components/layout-config-content/components/optionsbar-items/items/grid/enums/grid-type.enum';
import {
  GridModel
} from '@views/secured/beyond-core/settings/applications-settings/views/layouts/views/layout-config/components/layout-config-content/components/optionsbar-items/items/grid/models/grid.model';
import {
  GRID_LIST
} from '@views/secured/beyond-core/settings/applications-settings/views/layouts/views/layout-config/components/layout-config-content/components/optionsbar-items/items/grid/util/grid-list';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const DEFAULT_LOCALE_ID = 'sr-Latn';

// Password strength validation regex (https://regex101.com/library/L75p3B):
// - password must contain 1 number (0-9),
// - password must contain 1 uppercase letters,
// - password must contain 1 lowercase letters,
// - password must contain 1 non-alpha numeric number,
// - password has at least 8 characters without whitespace.
// The same regex is used on the server.
export const PASSWORD_PATTERN = '^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,}$';
export const TIME_PATTERN = '^(?:[01]\\d|2[0-3]):[0-5]\\d$';
export const URL_REGEX = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
export const NUMBERS_AND_LETTERS_REGEX = new RegExp('^[a-zA-Z0-9_ ]*$');
export const JMBG_PATTERN = '^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2])([09][0-9]{2})([0-8][0-9]|9[0-6])([0-9]{3})(\\d)$';

export function isUsernameAvailableAsyncValidator(isUsernameAvailableFn: (payload: { username: string }) => Observable<boolean>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return isUsernameAvailableFn({ username: control.value })
      .pipe(
        map((available: boolean) => (available ? null : { usernameNotAvailable: !available }))
      );
  };
}

export function isEmailAvailableAsyncValidator(isEmailAvailableFn: (payload: { email: string }) => Observable<boolean>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return isEmailAvailableFn({email: control.value})
      .pipe(
        map((available: boolean) => (available ? null : {emailNotAvailable: !available}))
      );
  };
}

export function isNameAvailableAsyncValidator(isNameAvailableFn: (payload: { name: string }) => Observable<boolean>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return isNameAvailableFn({name: control.value})
      .pipe(
        map((available: boolean) => (available ? null : {nameNotAvailable: !available}))
      );
  };
}

export function isDbNameAvailableAsyncValidator(isDbNameAvailableFn: (payload: { dbName: string }) => Observable<boolean>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return isDbNameAvailableFn({dbName: control.value})
      .pipe(
        map((available: boolean) => (available ? null : {dbNameNotAvailable: !available}))
      );
  };
}

export function isCodeAvailableAsyncValidator(isCodeAvailableFn: (payload: { code: string }) => Observable<boolean>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return isCodeAvailableFn({code: control.value})
      .pipe(
        map((available: boolean) => (available ? null : {codeNotAvailable: !available}))
      );
  };
}

export function getGridItem(type: GridTypeEnum): GridModel {
  const grid = GRID_LIST
    .find(g => g.name === type);

  if (!type) {
    console.error(`Not defined grid for grid type ${type}!`);
    return null;
  }

  return grid;
}

const canvas = document.createElement('canvas');

export function calcTextWidth(text: string, font: string): number {
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return Math.round(metrics.width);
}

export function getColumnNumber(name: string): number {
  const regex = /\[(\d+)\]/;
  const match = name.match(regex);
  return Number(match[1]);
}

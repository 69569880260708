import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationRestService } from '@base/modules/rest/authentication/authentication-rest.service';
import { CurrentUserContextResponseModel } from '@base/modules/rest/user/response/current-user-context-response.model';
import { UserFullResponseModel } from '@base/modules/rest/user/response/user-full-response.model';
import { UserRestService } from '@base/modules/rest/user/user-rest.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { fromEvent, interval, merge, of, timer } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { LoaderDialogComponent } from '@shared/components/dialogs/loader-dialog/loader-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserContext } from '../../services/user-context';
import { UserOnlineActivityRestService } from '../../modules/rest/user/user-online-activity-rest.service';
import { ObjectRestServiceCreator } from '../../modules/rest/object/object-rest-service-creator';
import { ObjectNameEnum } from '../../modules/rest/master-data-history/model/enums/object-name.enum';
import { OrganizacijaModel } from '../../modules/rest/mis4/O/organizacija.model';
import { reloadContext } from '../../utils/route.util';
import { setActiveOrganizationId } from '../../modules/rest/common/active-organization.util';
import * as fromActions from './actions';

const RECORD_ONLINE_ACTIVITY_INTERVAL = 5 * 60 * 1000; // 5 minutes

@Injectable()
export class CoreUserEffects {

  constructor(private actions$: Actions,
              private authenticationRestService: AuthenticationRestService,
              private router: Router,
              private route: ActivatedRoute,
              private userRestService: UserRestService,
              private userContext: UserContext,
              private userOnlineActivityRestService: UserOnlineActivityRestService,
              private objectRestServiceCreator: ObjectRestServiceCreator,
              private keycloakService: KeycloakService,
              private matDialog: MatDialog) {
  }

  clicks$ = fromEvent(document, 'click');
  keys$ = fromEvent(document, 'keydown');
  mouse$ = fromEvent(document, 'mousemove');

  logoutOnIdle$ = createEffect(() =>
    merge(this.clicks$, this.keys$, this.mouse$)
      .pipe(
        filter(() => !!this.userContext.user && !!this.userContext.user.logOutTimeInMS),
        switchMap(() => timer(this.userContext.user.logOutTimeInMS)),
        filter(() => !!this.userContext.user),
        map(() => fromActions.Logout())
      )
  );

  recordOnlineActivity$ = createEffect(() =>
      merge(interval(RECORD_ONLINE_ACTIVITY_INTERVAL), this.userContext.user$)
        .pipe(
          filter(() => !!this.userContext.user),
          exhaustMap(() => this.userOnlineActivityRestService.create()),
          catchError(() => {
            console.error('Error while saving online activity');
            return of('');
          })
        )
    , {dispatch: false});

  reloadUserContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.ReloadUserContext),
      exhaustMap(() => {

        return this.userRestService.currentUserContext()
          .pipe(
            map((user) => fromActions.ReloadUserContextSuccess({userContext: user}))
          );
      }))
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.Logout),
      exhaustMap(() => {

        return this.authenticationRestService.logout()
          .pipe(
            switchMap(() => {
              if (this.userContext.parameters.keycloakEnabled) {
                return fromPromise(this.keycloakService.logout(window.location.origin));
              } else {
                return of('not-important-text');
              }
            }),
            map(() => fromActions.LogoutSuccess())
          );
      }))
  );

  logoutSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.LogoutSuccess),
        tap(() => {
          this.userContext.removeProviderLocation();
          this.router.navigate(['/']);
        }))
    , {dispatch: false});

  updateUserOrganizationDefaultApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UpdateUserOrganizationDefaultApplication),
      exhaustMap((action) => {

        return this.userRestService.updateUserOrganizationDefaultApplication({
          data: {
            userId: action.userId,
            organizationId: action.organizationId,
            applicationId: action.applicationId,
          },
        })
          .pipe(
            map((user: UserFullResponseModel) => fromActions.UpdateUserOrganizationDefaultApplicationSuccess({user}))
          );
      }))
  );

  switchActiveOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SwitchActiveOrganization),
      tap(() => {
        LoaderDialogComponent.open(this.matDialog);
      }),
      exhaustMap((action) => {

        return this.userRestService.switchActiveOrganization({
          activeOrganizationId: action.activeOrganizationId,
        })
          .pipe(
            tap(() => setActiveOrganizationId(action.activeOrganizationId)),
            tap(() => reloadContext(this.router, this.route)),
            map((currentUserContextResponse: CurrentUserContextResponseModel) =>
              fromActions.SwitchActiveOrganizationSuccess({currentUserContextResponse}))
          );
      }))
  );

  loadActiveOrganizacija$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SwitchActiveOrganizationSuccess),
      exhaustMap((action) => {
        const restService = this.objectRestServiceCreator.create(ObjectNameEnum.Organizacija);
        return restService.findOne({id: action.currentUserContextResponse.activeOrganizationId})
          .pipe(
            map((organizacija: OrganizacijaModel) => fromActions.LoadActiveOrganizacijaSuccess({organizacija}))
          );
      }))
  );

  trackBettingLoginAllowed$ = createEffect(() =>
    this.userContext.bettingLoginAllowed$
      .pipe(
        filter((loginAllowed) => !loginAllowed),
        filter(() => !!this.userContext.user),
        map(() => fromActions.Logout())
      )
  );
}


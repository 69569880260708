<div class="c-snack-bar">
  <div class="c-snack-bar__body">
    <div [ngClass]="data.hasActions ? 'c-snack-bar__body-message--with-actions' : ''" class="c-snack-bar__body-message">{{ data.message || data.errorCode }}</div>
    <app-bui-button-icon
      [iconName]="'close'"
      (clickEvent)="closeDialog()"
      [backgroundStyle]="'transparent'"
      [comboStyle]="'fill-primary'"
      [borderStyle]="'transparent'">
    </app-bui-button-icon>
  </div>
  <div class="c-snack-bar__actions" *ngIf="data.hasActions">
      <app-bui-button-label
        [borderStyle]="'transparent'"
        [comboStyle]="'fill-primary'"
        [backgroundStyle]="'transparent'"
        (clickEvent)="openMoreDetailsDialog()">
        {{ 'MORE_DETAIL_ERROR_DIALOG.MORE_DETAILS' | translate }}
      </app-bui-button-label>
    <div class="c-snack-bar__actions-horizontal-line-container">
      <hr class="c-snack-bar__actions-horizontal-line">
    </div>
    <div class="c-snack-bar__action">
      <app-bui-button-label
        [borderStyle]="'transparent'"
        [comboStyle]="'fill-primary'"
        [backgroundStyle]="'transparent'"
        (clickEvent)="onDownloadClick()">
        {{ 'ACTIONS.DOWNLOAD' | translate }}
      </app-bui-button-label>
    </div>
  </div>
</div>

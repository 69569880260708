import { PipesModule } from '@base/pipes/pipes.module';
import { AngularMaterialModule } from '@shared/external-modules/angular-material.module';
import { BuiButtonIconComponent } from '@shared/modules/bui/bui-button-icon/bui-button-icon.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconModule } from '@shared/components/svg-icon/svg-icon.module';
import { WrapFnPipeModule } from '@base/pipes/wrap-fn.pipe';

@NgModule({
  declarations: [
    BuiButtonIconComponent,
  ],
  imports: [
    AngularMaterialModule,
    CommonModule,
    SvgIconModule,
    PipesModule,
    WrapFnPipeModule,
  ],
  exports: [
    BuiButtonIconComponent,
  ],
})
export class BuiButtonIconModule { }

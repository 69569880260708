import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';
import { MasterDataHistoryResponseModel } from './model/master-data-history-response.model';

@Injectable()
export class HistoryRestService {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/history';

  constructor(private httpClient: HttpClient, private appConfiguration: AppConfiguration) {
  }

  getHistoryForClass(payload: { objectName: ObjectNameEnum; itemId?: number; page: number; filter: string }): Observable<MasterDataHistoryResponseModel> {
    let queryParams = new HttpParams()
      .append('objectName', payload.objectName)
      .append('page', payload.page.toString())
      .append('filter', payload.filter);

    if (!!payload.itemId) {
      queryParams = queryParams
        .append('itemId', payload.itemId.toString());
    }

    return this.httpClient.get<MasterDataHistoryResponseModel>(this.controllerUrl, { params: queryParams });
  }
}

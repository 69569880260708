import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDialogTemplateFooterActionsThird]',
})
export class DialogTemplateFooterActionsThirdDirective {

  constructor(public tplRef: TemplateRef<any>) {
  }

}

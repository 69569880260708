import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NO_PERMISSION_PAGE, NOT_FOUND_PAGE, SELECT_TENANT_PAGE } from '@base/services/app-routes.service';
import { SnackBarService } from '@base/services/snackbar.service';
import { AppState } from '@base/store';
import { CoreUserActions } from '@base/store/user';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserContext } from '../../services/user-context';
import { getLanguage, LANGUAGE_HEADER } from './common/language.util';
import { getTenantId, TENANT_HEADER } from './common/tenants.util';
import { ACTIVE_ORGANIZATION_ID_HEADER, getActiveOrganizationId } from './common/active-organization.util';
import { ACTIVE_FISCAL_YEAR_ID_HEADER } from './common/active-fiscal-year.util';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private store: Store<AppState>,
              private snackBarService: SnackBarService,
              private userContext: UserContext) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = this.cloneRequest(request);
    const preventNotFoundRedirection: boolean = request.headers.get('prevent_not_found_redirection') === 'true';
    const preventForbiddenRedirection: boolean = request.headers.get('prevent_forbidden_redirection') === 'true';

    return next.handle(clonedRequest)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0) {
            this.snackBarService.openErrorMessage('Server is down');
          }

          // Navigate to login page if unauthorized
          if (error.status === 401 && !clonedRequest.url.endsWith('is-logged-in')) {
            this.store.dispatch(CoreUserActions.Logout());
          }

          if (error.status === 403 && !preventForbiddenRedirection) {
            this.router.navigate([NO_PERMISSION_PAGE]);
          }

          if (error.status >= 500 && error.status < 600) {
            this.snackBarService.openErrorMessage(error);
          }

          if (error.status === 404 && !preventNotFoundRedirection) {
            this.router.navigate([NOT_FOUND_PAGE]);
          }

          if (error.status === 400 && error.error.errorCode === 'TENANT_NOT_DEFINED') {
            const tenantId = this.getTenantIdFromUrl(window.location.href);
            this.router.navigate([SELECT_TENANT_PAGE], {
              queryParams: {'tenantId': tenantId},
              queryParamsHandling: 'merge'
            });
          }

          return throwError(() => error);
        })
      );
  }

  private cloneRequest(request: HttpRequest<any>): HttpRequest<any> {
    const tenantId = getTenantId();
    const language = getLanguage();
    let headers: HttpHeaders = request.headers
      .set(LANGUAGE_HEADER, language)
      .set(TENANT_HEADER, tenantId);

    if (this.userContext.aktivnaPoslovnaGodina) {
      headers = headers.set(ACTIVE_FISCAL_YEAR_ID_HEADER, `${this.userContext.aktivnaPoslovnaGodina?.id}`);
    }

    if (getActiveOrganizationId()) {
      headers = headers.set(ACTIVE_ORGANIZATION_ID_HEADER, `${getActiveOrganizationId()}`);
    }

    const withCredentials = this.userContext.parameters.corsAllowCredentials;
    return request.clone({
      withCredentials,
      headers,
    });
  }

  getTenantIdFromUrl(url: string): string | null {
    // Create an anchor element to use the browser's built-in URL parsing capabilities
    const anchor = document.createElement('a');
    anchor.href = url;

    // Get the hash part (everything after '#')
    const hash = anchor.hash;

    // Extract the part after the '#' and ensure it starts with '/'
    if (hash.startsWith('#/')) {
      const queryParamsString = hash.substring(2); // Remove the leading '#/'
      const queryParams = new URLSearchParams(queryParamsString.split('?')[1]);
      return queryParams.get('tenantId');
    }
    return null;
  }
}

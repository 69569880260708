import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appLayoutContentMainBody]',
})
export class LayoutContentMainBodyDirective {
  constructor(public tplRef: TemplateRef<any>) {
  }

}

export type BuiIconMat = 'mat';
export type BuiIconMdi = 'mdi';
export type BuiIconMaterial = BuiIconMat | BuiIconMdi;
export type BuiIconCustom = 'svg';
export type BuiIcon = BuiIconCustom | BuiIconMaterial;

export const BUI_ICON_TYPE_MAT: BuiIconMat = 'mat';
export const BUI_ICON_TYPE_MDI: BuiIconMdi = 'mdi';
export const BUI_ICON_TYPE_CUSTOM: BuiIconCustom = 'svg';
export const BUI_ICON_TYPE_DEFAULT = BUI_ICON_TYPE_MDI;

export const BUI_ICON_MATERIAL_ICON_TYPES: BuiIconMaterial[] = [BUI_ICON_TYPE_MAT, BUI_ICON_TYPE_MDI];
export function isBuiIconMaterialIconType(iconType: any): boolean {
  return BUI_ICON_MATERIAL_ICON_TYPES.includes(iconType as BuiIconMaterial);
}

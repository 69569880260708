import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDialogTemplateSearchableFooter]',
})
export class DialogTemplateSearchableFooterDirective {

  constructor(public tplRef: TemplateRef<any>) {}

}

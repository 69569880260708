import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationErrorDetailsDialogComponent } from '../dialogs/more-detail-dialog/application-error-details-dialog.component';
import { saveStringAsFile } from '../../util/file.util';

interface DialogData {
  message: string;
  stackTrace: string;
  errorCode: string;
  hasActions: boolean;
  closeSnackBar: () => void;
}

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: DialogData,
              private dialog: MatDialog) {
  }

  closeDialog(): void {
    this.data.closeSnackBar();
  }

  openMoreDetailsDialog(): void {
    ApplicationErrorDetailsDialogComponent.open(this.dialog, this.data);
  }

  onDownloadClick(): void {
    const fileContent = `${this.data.message} ${this.data.stackTrace}`;
    saveStringAsFile(fileContent, '.txt', 'Error_stack trace');
  }
}

export const DEFAULT_LANGUAGE = 'lat';
export const LANGUAGE_HEADER = 'language';

export function getLanguage(): string {
  return localStorage.getItem('language') || DEFAULT_LANGUAGE;
}

export function setLanguage(lang: string): void {
  localStorage.setItem('language', lang);
}

<div class="c-dialog-template-searchable"
  [ngClass]="[
    constructTheLayoutModifierClasses(),
  ]">
  <div class="c-dialog-template-searchable__header" *ngIf="!!headerDirective">
    <ng-container [ngTemplateOutlet]="headerDirective.tplRef"></ng-container>
  </div>
  <div class="c-dialog-template-searchable__subheader" *ngIf="!!subheaderDirective">
    <ng-container [ngTemplateOutlet]="subheaderDirective.tplRef"></ng-container>
  </div>
  <div class="c-dialog-template-searchable__body u-scrollable-y">
    <ng-container *ngIf="!bodyDirective">{{ '"appDialogTemplateSearchableBody" is mandatory!' }}</ng-container>
    <ng-container [ngTemplateOutlet]="bodyDirective.tplRef"></ng-container>
  </div>
  <div class="c-dialog-template-searchable__footer" *ngIf="!!footerDirective">
    <ng-container [ngTemplateOutlet]="footerDirective.tplRef"></ng-container>
  </div>
</div>

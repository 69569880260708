import { ObjectActionModel } from './object-action.model';

export class ObjectActionRequestModel implements ObjectActionModel {
  parts: { [key: string]: any };

  constructor(data: { action: string }) {
    this.parts = {
      action: data.action,
    };
  }

  put(key: string, value: any): void {
    this.parts[key] = value;
  }
}

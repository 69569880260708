<ng-container
  *ngIf="(loadingData$ | async) || (homePageLoading$ | async)">
  <app-bui-spinner></app-bui-spinner>
</ng-container>

<router-outlet>
  <ng-container *ngIf="isLoading">
    <app-bui-spinner></app-bui-spinner>
  </ng-container>
</router-outlet>

import { NgModule } from '@angular/core';
import { RestModule } from '@base/modules/rest/rest.module';
import { SecurityModule } from '@base/modules/security/security.module';
import { PipesModule } from '@base/pipes/pipes.module';
import { AppRoutesService } from '@base/services/app-routes.service';
import { NavigationHistoryService } from '@base/services/navigation-history.service';
import { UserContext } from '@base/services/user-context';
import { ROOT_EFFECTS, ROOT_REDUCERS } from '@base/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { WebSocketModule } from './modules/web-socket/web-socket.module';
import { CanCloseAppService } from './services/can-close-app.service';

@NgModule({
  imports: [
    StoreModule.forRoot(ROOT_REDUCERS,
      {
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictStateSerializability: true,
        },
      }),
    StoreDevtoolsModule.instrument({
      name: 'Beyond business suite',
    }),
    EffectsModule.forRoot(ROOT_EFFECTS),
    RestModule,
    SecurityModule,
    WebSocketModule,
  ],
  exports: [
    PipesModule,
  ],
  providers: [
    AppRoutesService,
    UserContext,
    NavigationHistoryService,
    CanCloseAppService,
  ],
})
export class CoreModule {
}

<div class="c-dialog-template"
  [ngClass]="[
    !!footer ? 'c-dialog-template--has-footer' : '', hasHeaderFooterGap ? 'c-dialog-template--has-header-footer-gap' : ''
  ]"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane">
  <div class="c-dialog-template__header" cdkDragHandle>
    <ng-content select="app-dialog-template-header"></ng-content>
  </div>
  <div class="c-dialog-template__body u-scrollable-y"
    #dialogTemplateBodyWrapper
    [ngClass]="[
      bodyHasNegativeSpaceX ? 'c-dialog-template__body--has-negative-space-x' : ''
    ]">
    <ng-content select="app-dialog-template-body"></ng-content>
  </div>
  <div class="c-dialog-template__footer">
    <ng-content select="app-dialog-template-footer"></ng-content>
  </div>
</div>

import { RxStompService } from '@stomp/ng2-stompjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMessage } from '@stomp/stompjs';
import { WebSocketMessage } from './web-socket-message';

@Injectable()
export class WebSocketService {

  constructor(private rxStompService: RxStompService) {
  }

  watch<T>(destination: string): Observable<WebSocketMessage<T>> {
    return this.rxStompService.watch(destination)
      .pipe(
        map((res: IMessage) => ({
          ...res,
        } as any))
      );
  }
}

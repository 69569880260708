import { Component, ContentChild, ElementRef, Input } from '@angular/core';
import { DialogTemplateFooterActionsFirstDirective } from '@shared/components/templates/dialog-template/dialog-template-footer/directives/dialog-template-footer-actions-first.directive';
import { DialogTemplateFooterActionsSecondDirective } from '@shared/components/templates/dialog-template/dialog-template-footer/directives/dialog-template-footer-actions-second.directive';
import { DialogTemplateFooterActionsThirdDirective } from '@shared/components/templates/dialog-template/dialog-template-footer/directives/dialog-template-footer-actions-third.directive';

@Component({
  selector: 'app-dialog-template-footer',
  templateUrl: './dialog-template-footer.component.html',
  styleUrls: ['./dialog-template-footer.component.scss'],
})
export class DialogTemplateFooterComponent {
  @Input() layout: 'simple' | 'advanced' = 'simple';

  @ContentChild(DialogTemplateFooterActionsFirstDirective) actionsFirstDirective: DialogTemplateFooterActionsFirstDirective;
  @ContentChild(DialogTemplateFooterActionsSecondDirective) actionsSecondDirective: DialogTemplateFooterActionsSecondDirective;
  @ContentChild(DialogTemplateFooterActionsThirdDirective) actionsThirdDirective: DialogTemplateFooterActionsThirdDirective;

  constructor(public elementRef: ElementRef) {
  }

}

import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appLayoutContentMainHeaderFiltersActions]',
})
export class LayoutContentMainHeaderFiltersActionsDirective {

  constructor(public tplRef: TemplateRef<any>) { }

}

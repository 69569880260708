import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogTemplateStyleEnum } from '@shared/components/templates/dialog-template/enums/dialog-template-style.enum';

@Component({
  selector: 'app-dialog-template-header',
  templateUrl: './dialog-template-header.component.html',
  styleUrls: ['./dialog-template-header.component.scss'],
})
export class DialogTemplateHeaderComponent {
  @Input() color: 'none' | 'primary' | 'warning' = 'none';
  @Input() style: DialogTemplateStyleEnum = DialogTemplateStyleEnum.DEFAULT;
  @Output() previousPageEvent = new EventEmitter();
  @Output() nextPageEvent = new EventEmitter();
}

import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ValidationErrorDirective } from '@shared/components/form-elements/directives/validation-error.directive';
import { isRequired } from '@shared/components/form-elements/form-elements.util';
import { HIDE_REQUIRED_MARKER } from '@shared/util/form.util';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTextareaComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder = '';
  @Input() tooltip: string;
  @Input() autoresize: boolean;
  @Input() cdkAutosizeMinRows: number;
  @Input() cdkAutosizeMaxRows: number;
  @Input() isFullWide: boolean;
  @Input() maxLength: number = 1000000000;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ContentChildren(ValidationErrorDirective) validationErrors: QueryList<ValidationErrorDirective>;

  required: boolean;
  hideRequiredMarker = HIDE_REQUIRED_MARKER;

  constructor(@Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.required = isRequired(this.ngControl);
  }

  registerOnChange(): void {
  }

  registerOnTouched(): void {
  }

  setDisabledState(): void {
  }

  writeValue(): void {
  }

  onFocus(event: any): void {
    event?.target?.select();
  }
}

import { formatDate } from '@angular/common';

export function saveArrayBufferAsFile(result: ArrayBuffer, fileName: string): void {
  const url = URL.createObjectURL(new Blob([result]));
  saveToDisk(url, fileName);
}

export function saveBlobAsFile(blob: Blob, fileName: string): void {
  const url = window.URL.createObjectURL(blob);
  saveToDisk(url, fileName);
}

export function saveStringAsFile(content: string, type: string, fileName: string): void {
  const blob = new Blob([content], {type});
  const url = window.URL.createObjectURL(blob);
  saveToDisk(url, fileName);
}

function saveToDisk(url: string, fileName: string): void {
  const file = document.createElement('a');
  file.href = url;
  file.download = fileName;
  file.click();
  URL.revokeObjectURL(url);
}

export function generateFileName(objectName: string, extension: string): string {
  return objectName + '_' + formatDate(new Date(), 'dd_MM_yyyy_HH_mm_ss', 'en_US') + extension;
}

export function openFileInNewWindow(val: Blob): void {
  const fileUrl = URL.createObjectURL(val);
  window.open(fileUrl);
}

export function downloadBlob(content: any, type: string, name: string): void {
  const data = new Blob([content], {type: type});
  const fileUrl = window.URL.createObjectURL(data);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = fileUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

export function base64ToByteArray(base64: string): Uint8Array {
  const binaryString = window.atob(base64);
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  return byteArray;
}

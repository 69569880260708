import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { LayoutContentMainBodyDirective } from '../../directives/layout-content-main-body.directive';
import { LayoutContentMainHeaderActionsDirective } from '../../directives/layout-content-main-header-actions.directive';
import {
  LayoutContentMainHeaderActiveFiltersDirective
} from '../../directives/layout-content-main-header-active-filters.directive';
import {
  LayoutContentMainHeaderFiltersActionsDirective
} from '../../directives/layout-content-main-header-filters-actions.directive';
import {
  LayoutContentMainHeaderFiltersSearchDirective
} from '../../directives/layout-content-main-header-filters-search.directive';
import { LayoutContentMainHeaderTitleDirective } from '../../directives/layout-content-main-header-title.directive';
import {
  LayoutContentMainHeaderViewTemplatesDirective
} from '../../directives/layout-content-main-header-view-templates.directive';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-layout-content-main',
  templateUrl: './layout-content-main.component.html',
  styleUrls: ['./layout-content-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutContentMainComponent {
  @Input() showBreadcrumbs = true;
  @Input() viewAsPage = true;
  @Input() fullScreenMode: boolean;
  @Input() headerHorizontal = false;
  @ContentChild(LayoutContentMainBodyDirective) bodyDirective: LayoutContentMainBodyDirective;
  @ContentChild(LayoutContentMainHeaderTitleDirective) headerTitleDirective: LayoutContentMainHeaderTitleDirective;
  @ContentChild(LayoutContentMainHeaderActionsDirective) headerActionsDirective: LayoutContentMainHeaderActionsDirective;
  @ContentChild(LayoutContentMainHeaderViewTemplatesDirective) headerViewTemplatesDirective: LayoutContentMainHeaderViewTemplatesDirective;
  @ContentChild(LayoutContentMainHeaderFiltersSearchDirective) headerFiltersSearchDirective: LayoutContentMainHeaderFiltersSearchDirective;
  @ContentChild(LayoutContentMainHeaderFiltersActionsDirective) headerFiltersActionsDirective: LayoutContentMainHeaderFiltersActionsDirective;
  @ContentChild(LayoutContentMainHeaderActiveFiltersDirective) headerActiveFiltersDirective: LayoutContentMainHeaderActiveFiltersDirective;
}

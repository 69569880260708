import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginResponseModel } from '@base/modules/rest/authentication/response/login-response.model';
import { AppConfiguration } from 'app/app-configuration';
import { DeviceInfo } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationRestService {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/public/authentication';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  login(payload: { username: string; password: string; deviceInfo: DeviceInfo }): Observable<LoginResponseModel> {
    const requestData = {
      username: payload.username,
      password: payload.password,
      deviceInfoDto: {
        browser: payload.deviceInfo.browser,
        browserVersion: payload.deviceInfo.browser_version,
        device: payload.deviceInfo.device,
        os: payload.deviceInfo.os,
        osVersion: payload.deviceInfo.os_version,
      },
      clientId: 'BEYOND_IDENTITY',
    };
    return this.httpClient.post<LoginResponseModel>(this.controllerUrl + '/login', requestData);
  }

  logout(): Observable<void> {
    return this.httpClient.get<void>(this.controllerUrl + '/logout');
  }

  resetPasswordInit(email: string): Observable<void> {
    return this.httpClient.post<void>(this.controllerUrl + '/password/reset-init', email);
  }

  checkResetPasswordTokenValidity(payload: { token: string }): Observable<void> {
    return this.httpClient.post<void>(this.controllerUrl + '/password/token/valid', {token: payload.token});
  }

  resetPassword(payload: { password: string; token: string }): Observable<void> {
    return this.httpClient.post<void>(this.controllerUrl + '/password/reset', payload);
  }
}

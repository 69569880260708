import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule, Provider } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { ErrorStateMatcher, MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import {
  MAT_COLOR_FORMATS,
  NGX_MAT_COLOR_FORMATS,
  NgxMatColorPickerModule
} from '@angular-material-components/color-picker';

export const MAT_DIALOG_DEFAULT_OPTIONS_PROVIDER: Provider = {
  provide: MAT_DIALOG_DEFAULT_OPTIONS,
  useValue: {
    hasBackdrop: true,
    disableClose: true,
  } as MatDialogConfig,
};

export const MAT_SNACK_BAR_DEFAULT_OPTIONS_PROVIDER: Provider = {
  provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
  useValue: {
    verticalPosition: 'top',
  } as MatSnackBarConfig,
};

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control && control.invalid && control.touched;
  }
}

export const ERROR_STATE_MATCHER_PROVIDER: Provider = {
  provide: ErrorStateMatcher,
  useValue: new MyErrorStateMatcher(),
};

export const MAT_COLOR_FORMATS_PROVIDER: Provider = {
  provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS,
};

const MODULES: any = [
  NgxMatColorPickerModule,
  ScrollingModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatButtonModule,
  MatMenuModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatProgressBarModule,
  MatDividerModule,
  MatSelectModule,
  MatOptionModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatAutocompleteModule,
  MatIconModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatExpansionModule,
  MatChipsModule,
  OverlayModule,
  NgxMatMomentModule,
];

const COMPONENTS: any = [
  MatLabel,
  MatHint,
];

@NgModule({
  imports: [
    ...MODULES,
  ],
  exports: [
    ...MODULES,
    ...COMPONENTS,
  ],
})
export class AngularMaterialModule {
}

export enum DialogTemplateTitleColorEnum {
  PRIMARY = 'PRIMARY',
  TEXT = 'TEXT',
  TEXT_INVERTED = 'TEXT_INVERTED',
  INHERIT = 'INHERIT',
}

export enum DialogTemplateTitleTextAlignEnum {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDialogTemplateSearchableBody]',
})
export class DialogTemplateSearchableBodyDirective {

  constructor(public tplRef: TemplateRef<any>) {}

}

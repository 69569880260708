import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { LoginToken } from '@views/public/login/model/login-token.model';
import { AppState } from '..';
import { BeyondSuiteRestService } from '../../modules/rest/authentication/beyondsuite-rest.service';
import { ApplicationNameEnum } from '../../enums/application-name.enum';
import * as fromActions from './actions';
import { CoreApplicationSelectors } from './index';
import { getTenantId } from '../../modules/rest/common/tenants.util';

@Injectable()
export class CoreApplicationEffects {
  parameters$ = this.store.select(CoreApplicationSelectors.parameters);

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private beyondSuiteRestService: BeyondSuiteRestService,
              private deviceService: DeviceDetectorService) {
  }

  openBeyondSuite$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof fromActions.OpenBeyondSuite>>(fromActions.OpenBeyondSuite.type),
      switchMap(action => {
        const deviceInfo: DeviceInfo = this.deviceService.getDeviceInfo();

        return this.beyondSuiteRestService.openBeyondSuite({clientId: action.clientId, deviceInfo: deviceInfo})
          .pipe(
            map((response: LoginToken) => {
              return fromActions.OpenBeyondSuiteSuccess({
                url: action.url,
                token: response.token,
                clientId: action.clientId,
              });
            })
          );
      }))
  );

  openBeyondSuiteSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType<ReturnType<typeof fromActions.OpenBeyondSuiteSuccess>>(fromActions.OpenBeyondSuiteSuccess.type),
        switchMap(action => {
          return this.parameters$
            .pipe(
              tap(parameters => {
                if (parameters.mis4App && action.clientId === ApplicationNameEnum.BEYOND_BACK_OFFICE) {
                  window.open(parameters.mis4App, '_blank');
                }
              }),
              map(() => action)
            );
        }),
        tap((action) => {
          let query = '';
          if (action.clientId === ApplicationNameEnum.BEYOND_PEOPLE ||
            action.clientId === ApplicationNameEnum.BEYOND_MANUFACTURING) {
            query = `token=${action.token}&tenant=${getTenantId()}`;
          }
          window.location.replace(`${action.url}?${query}`);
        })
      )
    , {dispatch: false});
}

import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appLayoutContentMainHeaderViewTemplates]',
})
export class LayoutContentMainHeaderViewTemplatesDirective {

  constructor(public tplRef: TemplateRef<any>) { }

}

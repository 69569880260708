import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SmartReportTemplateModel } from './model/smart-report-template.model';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable()
export class SmartReportTemplateRestService {

  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/smart-reports/';
  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  saveNewTemplate(payload: {smartReportTemplate: SmartReportTemplateModel; smartReport: number}): Observable<any> {
    return this.httpClient.post<any>(this.controllerUrl + `${payload.smartReport}/templates`, payload.smartReportTemplate);
  }

  deleteTemplate(payload: {smartReportTemplateId: number}): Observable<void> {
    return this.httpClient.delete<any>(this.controllerUrl + `${payload.smartReportTemplateId}/templates`);
  }

  updateTemplate(payload: {smartReportTemplateId: number; template: SmartReportTemplateModel}): Observable<SmartReportTemplateModel> {
    return this.httpClient.put<SmartReportTemplateModel>(this.controllerUrl + `${payload.smartReportTemplateId}/templates`, payload.template);
  }

  setTemplateAsDefault(payload: {templateId: number}): Observable<any> {
    return this.httpClient.post(this.controllerUrl + `${payload.templateId}/set-default-template`, {});
  }

  getAllSmartReportTemplates(payload: {smartReportId: number}): Observable<SmartReportTemplateModel[]> {
    return this.httpClient.get<SmartReportTemplateModel[]>(this.controllerUrl + `${payload.smartReportId}/templates`);
  }
}

import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-loader-dialog',
  templateUrl: './loader-dialog.component.html',
})
export class LoaderDialogComponent {

  static open(dialog: MatDialog): MatDialogRef<LoaderDialogComponent, void> {
    return dialog.open(LoaderDialogComponent, {
      width: '300px',
    });
  }
}

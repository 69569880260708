import { Moment } from 'moment-timezone';
import * as moment from 'moment';
import { PoslovnaGodinaModel } from '../modules/rest/mis4/P/poslovna-godina.model';
import { unitOfTime } from 'moment/moment';

export const MAT_DATE_FORMAT = 'DD.MM.YYYY.';
export const DATE_FORMAT = 'dd.MM.yyyy.';
export const DATETIME_FORMAT = 'dd.MM.yyyy. HH:mm';

export function now(utcOffset: boolean = true): Moment {
  const temp = moment();
  if (!utcOffset) {
    return temp;
  }
  return temp
    .utcOffset(0, true);
}

export function nowDate(): Moment {
  return now()
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);
}

export function clearTime(date: Moment, utcOffset: boolean = true): Moment {
  if (!date) {
    return date;
  }
  const temp = date
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);
  if (!utcOffset) {
    return temp;
  }
  return temp
    .utcOffset(0, true);
}

export function convertToDate(date: any, utcOffset: boolean = true): Moment {
  const temp = moment(date);
  if (!utcOffset) {
    return temp
      .local(true);
  }
  return temp
    .utcOffset(0, true);
}

export function toIsoString(date: any): string {
  if (!date) {
    return null;
  }
  if (Number.isInteger(date)) {
    return moment(date as number).toISOString();
  } else if (date instanceof Date) {
    return date.toISOString();
  } else {
    return convertToDate(date).toISOString();
  }
}

export function milisecondsToMoment(date: number): Moment {
  return moment(date);
}

export function addDays(date: Moment, days: number): Moment {
  return date.clone().add(days, 'days');
}

export function addHours(date: Moment, hours: number): Moment {
  return date.clone().add(hours, 'hours');
}

export function subtractDays(date: Moment, days: number): Moment {
  return date.clone().subtract(days, 'days');
}

export function diffDays(date1: Moment, date2: Moment, abs: boolean = false): number {
  return Math.abs(date1.diff(date2, 'days'));
}

export function areDatesEqual(date1: Moment, date2: Moment, granularity: unitOfTime.Base = 'days'): boolean {
  return date1.isSame(date2, granularity);
}

export function getYear(date: Moment): number {
  return date.year();
}

export function getCurrentYear(): number {
  return now().year();
}

export function getMonth(date: Moment): number {
  return date.month();
}

export function getMaxDayInMonth(date: Moment): number {
  const clone = date.clone();
  return clone.daysInMonth();
}

export function getLastDayInMonth(date: Moment): Moment {
  const clone = date.clone();
  clone.endOf('month');
  clone.startOf('day');
  return clone;
}

export function getLastDayOfYearDate(year: number): Moment {
  return now()
    .set('year', year)
    .endOf('year');
}

export function getFirstDayOfYearDate(year: number): Moment {
  return now()
    .set('year', year)
    .startOf('year');
}

export function getFirstDayOfCurrentYearDate(): Moment {
  return now()
    .startOf('year');
}

export function getLastDayOfCurrentYearDate(): Moment {
  return now()
    .endOf('year');
}

export function createDate(payload: { year: number; month: number; day: number }): Moment {
  const date = now();
  date.set('year', payload.year);
  date.set('month', payload.month);
  date.set('date', payload.day);
  date.startOf('day');
  return date;
}

export function getFirstDayOfMonthDate(originalDate: Moment): Moment {
  const clone = originalDate.clone();
  clone.startOf('month');
  return clone;
}

export function getFirstDayOfNextMonthDate(originalDate: Moment): Moment {
  const clone = originalDate.clone().add(1, 'month');
  clone.startOf('month');
  return clone;
}

export function getFiscalYearDate(fiscalYear: PoslovnaGodinaModel): Moment {
  let todayDate: Moment = now();
  if (fiscalYear) {
    if (todayDate.isBefore(fiscalYear.datumPocetka) || todayDate.isAfter(fiscalYear.datumZavrsetka)) {
      todayDate = fiscalYear.datumZavrsetka;
    }
  }
  return todayDate;
}

export function startOfYear(): Moment {
  return now().startOf('year');
}

export function getEndOfDay(originalDate: Moment): Moment {
  const clone = originalDate.clone();
  clone.endOf('day');
  return clone;
}

export function getPeriod(idPoslovneGodine: number): { datumOd: Moment; datumDo: Moment } {
  const period: { datumOd: Moment; datumDo: Moment } = {} as any;
  const currentYear = now().year();
  if (idPoslovneGodine === currentYear || !idPoslovneGodine) {
    period.datumOd = getFirstDayOfCurrentYearDate();
    period.datumDo = now();
    return period;
  }
  period.datumOd = getFirstDayOfYearDate(idPoslovneGodine);
  period.datumDo = getLastDayOfYearDate(idPoslovneGodine);
  return period;
}

export function timeToDate(time: string): Moment {
  if (!time) {
    return null;
  }
  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));
  date.setSeconds(0);
  date.setMilliseconds(0);
  return moment(date);
}

import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appLayoutContentMainHeaderFiltersSearch]',
})
export class LayoutContentMainHeaderFiltersSearchDirective {

  constructor(public tplRef: TemplateRef<any>) { }

}

export const isValueString = val => typeof val === 'string' || val instanceof String;

export const valueOnPath = (obj: any, path: string): any => {
  if (!obj || !path) {
    return '';
  }

  path = path.replace(/\[(\w+)\]/g, '.$1');
  path = path.replace(/^\./, '');
  const parts = path.split('.');
  for (let i = 0, n = parts.length; i < n; ++i) {
    const k = parts[i];
    if (typeof obj !== 'object') {
      return '';
    }
    if (!obj) {
      return '';
    }
    if (k in obj) {
      obj = obj[k];
    } else {
      return '';
    }
  }
  return obj;
};

export const setValue = (obj, path, value): void => {
  if (!obj) {
    return;
  }

  let schema = obj;
  const parts = path.split('.');
  const len = parts.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = parts[i];
    if (!schema[elem]) {
      schema[elem] = {};
    }
    schema = schema[elem];
  }
  schema[parts[len - 1]] = value;
};

export function pathGetFirst(path: string): string {
  if (!path) {
    return path;
  }
  const parts = path.split('.');
  if (!parts || !parts.length) {
    return '';
  }
  return parts[0];
}

export function pathSkipFirst(path: string): string {
  if (!path) {
    return path;
  }
  const parts = path.split('.');
  if (!parts || !parts.length || parts.length < 2) {
    return '';
  }
  return path.slice(`${parts[0]}.`.length);
}

export function pathSkipLast(path: string): string {
  if (!path) {
    return path;
  }
  const parts = path.split('.');
  if (!parts || !parts.length || parts.length < 2) {
    return '';
  }
  return path.slice(0, path.length - parts[parts.length - 1].length - 1);
}

export function pathGetLast(path: string): string {
  if (!path) {
    return path;
  }
  const parts = path.split('.');
  if (!parts || !parts.length) {
    return '';
  }
  return parts[parts.length - 1];
}

export function parsePathAndSkipFirst(path: string): string[] {
  if (!path) {
    return [];
  }
  const parts = path.split('.');
  if (!parts || !parts.length || parts.length < 2) {
    return [];
  }
  return [...parts.slice(1)];
}

export function capitalizePath(path: string): string {
  if (!path) {
    return '';
  }
  const parts = path.split('.');
  return parts
    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
    .reduce((acc, curr) => {
      if (acc !== '') {
        return `${acc}.${curr}`;
      }
      return curr;
    }, '');
}

export function isPath(path: string): boolean {
  if (!path) {
    return false;
  }
  const parts = path.split('.');
  return parts.length > 1;
}

export function clone(val: any): any {
  if (!val) {
    return val;
  }
  return JSON.parse(JSON.stringify(val));
}

export function cleanObject(data: any): void {
  if (!data) {
    return;
  }
  Object.keys(data)
    .forEach((k) => {
      if (data[k] === null || data[k] === undefined) {
        delete data[k];
      }
    });
}

import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appLayoutContentMainHeaderActions]',
})
export class LayoutContentMainHeaderActionsDirective {

  constructor(public tplRef: TemplateRef<any>) { }

}

<div class="c-breadcrumbs" *ngIf="(breadcrumbs$ | async)?.length">
  <ng-container *ngFor="let item of (breadcrumbs$ | async); let last = last">
    <span class="c-breadcrumbs__item">
      <ng-container *ngIf="!last">
        <span class="c-breadcrumbs__link"
          (click)="navigate(item)">
          {{ item.text | translate | truncate }}
        </span>
      </ng-container>
      <ng-container *ngIf="last">
        <span class="c-breadcrumbs__current">
          {{ item.text | translate | truncate }}
        </span>
      </ng-container>
    </span>
    <ng-container *ngIf="!last">
      <span class="c-breadcrumbs__separator">&gt;</span>
    </ng-container>
  </ng-container>
</div>

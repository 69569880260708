<app-dialog-template class="c-more-detail-dialog">
  <app-dialog-template-header>
    <app-dialog-template-title>{{ 'COMMON.INFO' | translate }}</app-dialog-template-title>
    <app-dialog-template-header-actions></app-dialog-template-header-actions>
  </app-dialog-template-header>
  <app-dialog-template-body>
    <div class="c-more-detail-dialog__body">
      <div class="c-more-detail-dialog__body-icon">
        <app-bui-button-icon
          [iconType]="BUI_ICON_TYPE_MAT"
          [iconName]="'content_copy'"
          [contentColor]="'dark'"
          [comboStyle]="'settings'"
          [matTooltip]="'Copy to clipboard'"
          [cdkCopyToClipboard]="descriptionFormControl.value"
          (cdkCopyToClipboardCopied)="showConfirmation()">
        </app-bui-button-icon>
      </div>
      <div>
        {{data.message}}
      </div>
      <div>
        Stack trace: {{data.stackTrace}}
      </div>
    </div>
  </app-dialog-template-body>
  <app-dialog-template-footer>
    <app-bui-button-label
      [comboStyle]="'outline'"
      (clickEvent)="onCancelClick()">
      {{ 'COMMON.CANCEL' | translate }}
    </app-bui-button-label>
    <app-bui-button-label
      [comboStyle]="DialogTemplateButtonComboStyleEnum.FILL_GRADIENT_1"
      (clickEvent)="onDownloadClick()">
      {{ 'ACTIONS.DOWNLOAD' | translate }}
    </app-bui-button-label>
  </app-dialog-template-footer>
</app-dialog-template>

import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { concatWith, defer, Observable, of } from 'rxjs';
import { ElementRef } from '@angular/core';

export const HIDE_REQUIRED_MARKER = false;

export function valueChanges(control: AbstractControl): Observable<any> {
  return defer(() => of(control.value))
    .pipe(concatWith(control.valueChanges));
}

export function markAllAsTouched(form: UntypedFormGroup): void {
  (Object as any).values(form.controls)
    .forEach((control: AbstractControl) => {
      if (!control.touched) {
        control.markAsTouched();
        control.updateValueAndValidity({onlySelf: true});
        if ((control as UntypedFormGroup).controls) {
          markAllAsTouched(control as UntypedFormGroup);
        }
      }
    });
}

// quick fix for https://github.com/angular/angular/issues/41519
export function focusFirstAsyncInput(context: ElementRef): void {
  const input: any = context.nativeElement.querySelector('.ng-pending input');
  if (input) {
    input.focus();
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSizesEnum } from '@shared/modules/bui/bui-icon/enums/icon-sizes.enum';
import { IconTypesEnum } from '@shared/modules/bui/bui-icon/enums/icon-types.enum';

@Component({
  selector: 'app-bui-icon-container',
  templateUrl: './bui-icon-container.component.html',
  styleUrls: ['./bui-icon-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuiIconContainerComponent {
  @Input() type: IconTypesEnum = IconTypesEnum.MDI;
  @Input() name: string;
  @Input() inline = false;
  @Input() size: IconSizesEnum = IconSizesEnum.M;
}

import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bui-button-label',
  templateUrl: './bui-button-label.component.html',
  styleUrls: ['./bui-button-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuiButtonLabelComponent implements OnInit {

  @Input() backgroundStyle: 'primary' | 'error' | 'dark' | 'medium' | 'accent' | 'light' | 'transparent' | 'gradient-1';
  @Input() borderStyle: 'intense' | 'dark' | 'medium' | 'light' | 'transparent';
  @Input() contentType: 'button' | 'dropdown' | 'text';
  @Input() contentColor: 'primary' | 'dark' | 'medium' | 'accent' | 'link' | 'light';
  @Input() contentStyle: 'intense' | 'gentle';
  @Input() dropdownCaretColor: 'dark' | 'medium' | 'light';
  @Input() comboStyle: 'fill-primary' | 'fill-accent' | 'fill-error' | 'fill-gradient-1' | 'outline' | 'outline-accent' | 'dropdown' | 'naked-dark' | 'naked-medium' | 'naked-link';
  @Input() disabled = false;
  @Input() tabIndex: number;

  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();
  @HostBinding('class.is-full-wide') @Input() isFullWide = false;

  isLink = false;

  ngOnInit(): void {
    if (this.comboStyle === 'fill-primary') {
      this.backgroundStyle = this.backgroundStyle || 'primary';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentType = this.contentType || 'button';
      this.contentColor = this.contentColor || 'light';
      this.contentStyle = this.contentStyle || 'intense';
      this.dropdownCaretColor = this.dropdownCaretColor || 'light';
    }
    if (this.comboStyle === 'fill-accent') {
      this.backgroundStyle = this.backgroundStyle || 'accent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentType = this.contentType || 'button';
      this.contentColor = this.contentColor || 'light';
      this.contentStyle = this.contentStyle || 'intense';
      this.dropdownCaretColor = this.dropdownCaretColor || 'light';
    }
    if (this.comboStyle === 'fill-error') {
      this.backgroundStyle = this.backgroundStyle || 'error';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentType = this.contentType || 'button';
      this.contentColor = this.contentColor || 'light';
      this.contentStyle = this.contentStyle || 'intense';
      this.dropdownCaretColor = this.dropdownCaretColor || 'light';
    }
    if (this.comboStyle === 'fill-gradient-1') {
      this.backgroundStyle = this.backgroundStyle || 'gradient-1';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentType = this.contentType || 'button';
      this.contentColor = this.contentColor || 'light';
      this.contentStyle = this.contentStyle || 'intense';
      this.dropdownCaretColor = this.dropdownCaretColor || 'light';
    }
    if (this.comboStyle === 'outline') {
      this.backgroundStyle = this.backgroundStyle || 'light';
      this.borderStyle = this.borderStyle || 'intense';
      this.contentType = this.contentType || 'button';
      this.contentColor = this.contentColor || 'medium';
      this.contentStyle = this.contentStyle || 'intense';
      this.dropdownCaretColor = this.dropdownCaretColor || 'medium';
    }
    if (this.comboStyle === 'outline-accent') {
      this.backgroundStyle = this.backgroundStyle || 'light';
      this.borderStyle = this.borderStyle || 'medium';
      this.contentType = this.contentType || 'button';
      this.contentColor = this.contentColor || 'accent';
      this.contentStyle = this.contentStyle || 'intense';
      this.dropdownCaretColor = this.dropdownCaretColor || 'medium';
    }
    if (this.comboStyle === 'dropdown') {
      this.backgroundStyle = this.backgroundStyle || 'medium';
      this.borderStyle = this.borderStyle || 'medium';
      this.contentType = this.contentType || 'dropdown';
      this.contentColor = this.contentColor || 'medium';
      this.contentStyle = this.contentStyle || 'gentle';
      this.dropdownCaretColor = this.dropdownCaretColor || 'medium';
    }
    if (this.comboStyle === 'naked-dark') {
      this.backgroundStyle = this.backgroundStyle || 'transparent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentType = this.contentType || 'text';
      this.contentColor = this.contentColor || 'dark';
      this.contentStyle = this.contentStyle || 'gentle';
      this.dropdownCaretColor = this.dropdownCaretColor || 'dark';
    }
    if (this.comboStyle === 'naked-medium') {
      this.backgroundStyle = this.backgroundStyle || 'transparent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentType = this.contentType || 'text';
      this.contentColor = this.contentColor || 'medium';
      this.contentStyle = this.contentStyle || 'gentle';
      this.dropdownCaretColor = this.dropdownCaretColor || 'dark';
    }
    if (this.comboStyle === 'naked-link') {
      this.isLink = true;
      this.backgroundStyle = this.backgroundStyle || 'transparent';
      this.borderStyle = this.borderStyle || 'transparent';
      this.contentType = this.contentType || 'text';
      this.contentColor = this.contentColor || 'link';
      this.contentStyle = this.contentStyle || 'gentle';
      this.dropdownCaretColor = this.dropdownCaretColor || 'dark';
    }
  }

  onClick(event: any): void {
    if (!this.disabled) {
      this.clickEvent.emit(event);
    }
  }
}

<mat-form-field appearance="outline" color="accent" floatLabel="always" [hideRequiredMarker]="hideRequiredMarker"
  [ngClass]="[
    isFullWide ? 'u-mat-full' : ''
  ]">
  <mat-label>
    <ng-container *ngIf="required">{{ label }}</ng-container>
    <ng-container *ngIf="!required">{{ label | optionalFieldTranslate }}</ng-container>
    <app-bui-tooltip-icon *ngIf="tooltip"
      [tooltip]="tooltip">
    </app-bui-tooltip-icon>
  </mat-label>
  <ng-container *ngIf="autoresize">
    <textarea
      matInput
      [cdkTextareaAutosize]="autoresize"
      #autosize="cdkTextareaAutosize"
      [cdkAutosizeMinRows]="cdkAutosizeMinRows"
      [cdkAutosizeMaxRows]="cdkAutosizeMaxRows"
      [attr.placeholder]="placeholder"
      [formControl]="$any(ngControl.control)"
      (focus)="onFocus($event)">
    </textarea>
  </ng-container>
  <ng-container *ngIf="!autoresize">
    <textarea
      matInput
      [maxLength]="maxLength"
      [attr.placeholder]="placeholder"
      [formControl]="$any(ngControl.control)"
      (focus)="onFocus($event)">
    </textarea>
  </ng-container>
  <mat-error *ngIf="ngControl.errors?.required">{{ 'VALIDATIONS.FIELD_REQUIRED' | translate }}</mat-error>
  <mat-error *ngFor="let error of validationErrors">
    <ng-container [ngTemplateOutlet]="error.tmpRef"></ng-container>
  </mat-error>
</mat-form-field>

import { SelectOptionModel } from '@shared/components/form-elements/select/model/select.option.model';

export enum LanguageEnum {
  en = 'en',
  lat = 'lat',
  cir = 'cir',
  hrv = 'hrv',
  slo = 'slo',
  mne = 'mne',
  mk = 'mk',
  ba = 'ba',
}

export const LANGUAGES = [
  {value: LanguageEnum.en, label: 'English', flag: 'country-flag-united-kingdom'},
  {value: LanguageEnum.lat, label: 'Srpski (latinica)', flag: 'country-flag-serbia'},
  {value: LanguageEnum.cir, label: 'Српски (ћирилица)', flag: 'country-flag-serbia'},
  {value: LanguageEnum.hrv, label: 'Hrvatski', flag: 'country-flag-croatia'},
  {value: LanguageEnum.slo, label: 'Slovenian', flag: 'country-flag-slovenia'},
  {value: LanguageEnum.mne, label: 'Crnogorski', flag: 'country-flag-montenegro'},
  {value: LanguageEnum.mk, label: 'Makedonski', flag: 'country-flag-macedonia'},
  {value: LanguageEnum.ba, label: 'Bosanski', flag: 'country-flag-bosnia-herzegovina'},
];

export const LANGUAGES_OPTIONS: SelectOptionModel[] = LANGUAGES
  .map((lang) => ({value: lang.value, data: lang.label}));

export interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

export const LANGUAGES_FLAGS: LanguageFlag[] = LANGUAGES
  .map((lang) => ({lang: lang.value, name: lang.label, flag: lang.flag}));

import { Pipe, PipeTransform } from '@angular/core';
import { LANGUAGES } from '../utils/language.util';

@Pipe({
  name: 'transformLanguage',
})
export class TransformLanguagePipe implements PipeTransform {

  transform(value: string): string {
    const lang = LANGUAGES
      .find((l) => l.value === value);
    return lang ? lang.label : '';
  }
}

import { AbstractControl, NgControl } from '@angular/forms';

export function isRequired(ngControl: NgControl): boolean {
  if (!ngControl) {
    return false;
  }
  if (ngControl.control && ngControl.control.validator) {
    const validator = ngControl.control.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  return false;
}

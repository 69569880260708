import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BUI_ICON_TYPE_CUSTOM,
  BUI_ICON_TYPE_DEFAULT,
  BuiIcon,
  BuiIconCustom,
  isBuiIconMaterialIconType,
} from '@base/utils/icon-types.util';

@Component({
  selector: 'app-bui-icon',
  templateUrl: './bui-icon.component.html',
  styleUrls: ['./bui-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuiIconComponent {
  @Input() type: BuiIcon = BUI_ICON_TYPE_DEFAULT;
  @Input() name: string;
  @Input() inline = false;

  protected readonly isBuiIconMaterialIconType = isBuiIconMaterialIconType;
  iconCustom: BuiIconCustom = BUI_ICON_TYPE_CUSTOM;
}

<div class="c-dialog-template-footer"
  [ngClass]="[
    layout ? 'c-dialog-template-footer--layout-' + (layout | toCssClassName) : ''
  ]">
  <div class="c-dialog-template-footer__actions"
    [ngClass]="[
      layout ? 'c-dialog-template-footer__actions--layout-' + (layout | toCssClassName) : ''
    ]">
    <ng-container *ngIf="layout === 'simple'">
      <ng-content></ng-content>
    </ng-container>

    <ng-container *ngIf="layout === 'advanced'">
      <div class="c-dialog-template-footer__action c-dialog-template-footer__action--first" *ngIf="!!actionsFirstDirective">
        <ng-container [ngTemplateOutlet]="actionsFirstDirective.tplRef"></ng-container>
      </div>
      <div class="c-dialog-template-footer__action c-dialog-template-footer__action--second" *ngIf="!!actionsSecondDirective">
        <ng-container [ngTemplateOutlet]="actionsSecondDirective.tplRef"></ng-container>
      </div>
      <div class="c-dialog-template-footer__action c-dialog-template-footer__action--third" *ngIf="!!actionsThirdDirective">
        <ng-container [ngTemplateOutlet]="actionsThirdDirective.tplRef"></ng-container>
      </div>
    </ng-container>
  </div>
</div>

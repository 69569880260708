<div class="c-dialog-template-header"
  [ngClass]="[
    color ? 'c-dialog-template-header--color-' + (color | toCssClassName) : '',
    style ? 'c-dialog-template-header--style-' + (style | toCssClassName) : ''
  ]">
  <ng-content select="app-dialog-template-icon"></ng-content>
  <ng-content select="app-dialog-template-title"></ng-content>
  <ng-content select="app-dialog-template-pager"></ng-content>
  <ng-content select="app-dialog-template-header-actions"></ng-content>
</div>

import { Injectable } from '@angular/core';
import { ApplicationNameEnum } from '../enums/application-name.enum';

// Public views
export const LOGIN_PAGE = '/login';
export const REGISTRATION_PAGE = '/registration';
export const PASSWORD_RESET_PAGE = '/password/reset';
export const SELECT_TENANT_PAGE = '/select-tenant';

// Secured views
export const HOME_PAGE = '/home';
export const NO_PERMISSION_PAGE = '/no-permission';
export const NOT_FOUND_PAGE = '/not-found';

// CORE
export const BEYOND_CORE_SETTINGS_ROOT = `${ApplicationNameEnum.BEYOND_CORE_SETTINGS}`;

// MTM
export const BEYOND_MOBILE_TIME_MANAGEMENT_ROOT = `/${ApplicationNameEnum.BEYOND_MOBILE_TIME_MANAGEMENT}`;
export const LEAVE_REQUESTS_ROOT_PAGE = `${BEYOND_MOBILE_TIME_MANAGEMENT_ROOT}/leave-requests`;
export const LEAVE_REQUEST_ADD_PAGE = `${LEAVE_REQUESTS_ROOT_PAGE}/add`;
export const LEAVE_REQUESTS_EMPLOYEES_PAGE = `${LEAVE_REQUESTS_ROOT_PAGE}/employees`;
export const LEAVE_REQUESTS_PERSONAL_PAGE = `${LEAVE_REQUESTS_ROOT_PAGE}/personal`;

@Injectable()
export class AppRoutesService {
}

import { NgModule } from '@angular/core';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { AppConfiguration } from '../../../app-configuration';
import { rxStompConfig } from './config/rx-stomp.config';
import { MonitoringSocketService } from './user/monitoring-socket.service';
import { WebSocketService } from './common/web-socket.service';

@NgModule({
  providers: [
    {
      provide: InjectableRxStompConfig,
      useFactory: rxStompConfig,
      deps: [AppConfiguration],
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig],
    },
    WebSocketService,
    MonitoringSocketService,
  ],
})
export class WebSocketModule {
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonReportDataModel } from '@base/modules/rest/print-template/model/json-report-data.model';
import { AppConfiguration } from '../../../../app-configuration';
import { ObjectPrintRequestModel } from './model/object-print-request.model';

@Injectable()
export class PrintTemplateRestService {
  controllerUrl: string;

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
    this.controllerUrl = this.appConfiguration.serverApiUrl + '/secured/print';
  }

  getAllTemplates(payload: { objectName: string }): Observable<any> {
    return this.httpClient.get(`${this.controllerUrl}/${payload.objectName}`);
  }

  getBeyondTemplates(payload: { objectName: string }): Observable<any> {
    return this.httpClient.get(`${this.controllerUrl}/${payload.objectName}/print-template`);
  }

  printPDF(payload: {
    objectName: string;
    data: ObjectPrintRequestModel
  }): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.controllerUrl}/${payload.objectName}/pdf`, payload.data, {responseType: 'blob' as 'json'});
  }

  getPrintJson(payload: {
    objectName: string;
    data: ObjectPrintRequestModel;
  }): Observable<JsonReportDataModel> {
    return this.httpClient.post<JsonReportDataModel>(`${this.controllerUrl}/${payload.objectName}/pdf/json`, payload.data);
  }

  printMultiPDF(payload: {
    objectName: string;
    data: ObjectPrintRequestModel
  }): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.controllerUrl}/${payload.objectName}/multi/pdf`, payload.data, {responseType: 'blob' as 'json'});
  }

  getJasperParameters(payload: { objectName: string }): Observable<any> {
    return this.httpClient.get(`${this.controllerUrl}/jasper-parameters`);
  }
}

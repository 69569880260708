import { ObjectCompositeFilterModel } from '@base/modules/rest/object/model/object-composite-filter.model';
import { ObjectCompositeFilterItemModel } from '@shared/components/filter/model/object-composite-filter-item.model';
import { FilterOperationEnum } from '@shared/components/filter/enums/filter-operation.enum';
import { ExpressionOperatorEnum } from '@base/modules/rest/object/enums/expression-operator.enum';
import { createObjectField, CustomFieldModel } from '@base/modules/rest/custom-field/model/custom-field.model';
import { CustomFieldTypesEnum } from '@base/modules/rest/custom-field/enums/custom-field-types.enum';
import { pathGetLast } from '@base/utils/common.util';
import * as moment from 'moment-timezone';
import { clearTime, MAT_DATE_FORMAT } from '@base/utils/date.util';
import { ObjectDefinitionModel } from '@base/modules/rest/bbs-object/model/object-definition.model';

export const DEFAULT_OPERATION_FILTER = FilterOperationEnum.EQUALS;

export function prepareCompositeFilter(compositeFilter: ObjectCompositeFilterModel, filter: string, fields: CustomFieldModel[]): ObjectCompositeFilterModel {
  if (!compositeFilter && !filter) {
    return undefined;
  }
  const filterSearch = prepareSearchFilter(filter, fields);
  const previousCompositeFilter: ObjectCompositeFilterModel = {
    filters: compositeFilter?.filters?.map(f => {
      if (f?.value?.id) {
        f = {
          ...f,
          value: f?.value?.id,
        };
      }
      return f;
    }) || [],
    expression: compositeFilter?.expression || '',
  };
  const expression = mergeCompositeFilters(filterSearch, previousCompositeFilter);
  return {
    id: compositeFilter?.id,
    filters: [...filterSearch.filters, ...previousCompositeFilter.filters],
    showChips: compositeFilter?.showChips,
    expression: expression,
  };
}

export function mergeCompositeFilters(filter1: ObjectCompositeFilterModel, filter2: ObjectCompositeFilterModel): string {
  let expression = '';
  if (filter1?.expression) {
    expression += filter1.expression;
  }
  if (filter2?.expression) {
    if (expression) {
      expression = `(${expression}) AND (${filter2.expression})`;
    } else {
      expression += filter2.expression;
    }
  }
  return expression;
}

export function prepareSearchFilter(filter: string, fields: CustomFieldModel[]): ObjectCompositeFilterModel {
  if (!filter || filter.length === 0) {
    return {
      filters: [],
      expression: '',
    };
  }
  const compositeFilter = {filters: [], expression: ''};
  fields
    .filter(field => field.visible && field.filterable)
    .forEach((field, i) => {
      if (field.type === CustomFieldTypesEnum.LOOKUP) {
        const mainFieldFilter = createFieldFilter(`item_${i}_1`, field.path, field?.lookupObject?.mainField, filter);
        const secondFieldFilter = createFieldFilter(`item_${i}_2`, field.path, field?.lookupObject?.secondField, filter);
        compositeFilter.filters = [...compositeFilter.filters, mainFieldFilter, secondFieldFilter];
      } else if (field.type === CustomFieldTypesEnum.DATE) {
        const dateValue = moment(filter, MAT_DATE_FORMAT, true);
        const isDateValid = dateValue.isValid();
        if (isDateValid) {
          const filterItem: ObjectCompositeFilterItemModel = createFieldFilterItem(`item_lookup_${i}`, field, clearTime(dateValue), FilterOperationEnum.EQUALS);
          compositeFilter.filters = [...compositeFilter.filters, filterItem];
        }
      } else {
        const filterItem: ObjectCompositeFilterItemModel = createFieldFilterItem(`item_lookup_${i}`, field, filter);
        compositeFilter.filters = [...compositeFilter.filters, filterItem];
      }
    });
  generateCompositeFilterExpression(compositeFilter, ExpressionOperatorEnum.OR);
  return compositeFilter;
}

export function createFieldFilter(name: string, path: string, fieldName: string, filter: any, operator?: FilterOperationEnum): ObjectCompositeFilterItemModel {
  return {
    name,
    beanName: path,
    fieldName: fieldName,
    operation: operator ?? FilterOperationEnum.CONTAINS,
    value: filter,
  };
}

export function createFieldFilterItem(name: string,
                                      field: CustomFieldModel,
                                      filter: any,
                                      operator?: FilterOperationEnum): ObjectCompositeFilterItemModel {
  let fieldName: string;
  if (field.type === CustomFieldTypesEnum.LOOKUP) {
    fieldName = 'id';
  } else {
    fieldName = pathGetLast(field.dbName);
  }
  const _operator = operator ?? (fieldName === 'id' ? FilterOperationEnum.EQUALS : FilterOperationEnum.CONTAINS);
  return {
    name: name,
    beanName: field.path,
    fieldName: fieldName,
    operation: _operator,
    value: filter,
  };
}

export function generateCompositeFilterExpression(compositeFilter: ObjectCompositeFilterModel,
                                                  expressionOperator: ExpressionOperatorEnum): void {
  compositeFilter.expression = '';
  compositeFilter.filters
    .forEach((f: ObjectCompositeFilterItemModel, i: number, array: ObjectCompositeFilterItemModel[]) => {
        const snippet = i !== array.length - 1 ? `${f.name} ${expressionOperator} ` : `${f.name}`;
        f.displayOrder = i + 1;
        compositeFilter.expression = compositeFilter.expression.concat(snippet);
      }
    );
}

export function prepareLookupFilter(object: ObjectDefinitionModel, searchFilter: string): ObjectCompositeFilterModel {
  const fields: CustomFieldModel[] = [];
  if (!!object.mainField) {
    const field = findField(object, object.mainField);
    if (!!field) {
      fields.push(field);
    } else {
      fields.push(createObjectField(object.name, object.mainField));
    }
  }
  if (!!object.secondField) {
    const field = findField(object, object.secondField);
    if (!!field) {
      fields.push(field);
    } else {
      fields.push(createObjectField(object.name, object.secondField));
    }
  }
  return prepareSearchFilter(searchFilter, fields);
}

export function prepareFilter(object: ObjectDefinitionModel, filter: ObjectCompositeFilterModel, searchFilter: string): ObjectCompositeFilterModel {
  const lookupFilter = prepareLookupFilter(object, searchFilter);
  if (filter) {
    lookupFilter.filters.push(...filter.filters);
    if (filter?.expression) {
      if (lookupFilter.expression?.length) {
        lookupFilter.expression = `(${lookupFilter.expression}) AND `;
      }
      lookupFilter.expression += `(${filter.expression})`;
    }
  }
  return lookupFilter;
}

function findField(object: ObjectDefinitionModel, dbName: string): CustomFieldModel {
  return object.fields?.find(f => f.dbName === dbName);
}

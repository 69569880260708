import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable()
export class CacheRestService {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/cache';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  clearApplicationCache() {
    return this.httpClient.delete<void>(`${this.controllerUrl}/init-cache`);
  }

}

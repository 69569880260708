import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebSocketService } from '../common/web-socket.service';
import { WebSocketMessage } from '../common/web-socket-message';

@Injectable()
export class MonitoringSocketService {

  constructor(private webSocketService: WebSocketService) {
  }

  scriptResult(uid: string): Observable<WebSocketMessage<any>> {
    return this.webSocketService.watch(`/monitoring-uid/${uid}/queue/script-output`);
  }
}

import { Directive, ElementRef, EventEmitter, Input, NgModule, NgZone, OnDestroy, Output } from '@angular/core';

export interface MQ {
  type: 'max-width' | 'min-width';
  value: number;
}

@Directive({
  selector: '[appElementResize]',
})
export class ElementResizeDirective implements OnDestroy {
  @Input() mq: MQ;
  @Output() resizedEvent = new EventEmitter<boolean>();
  private previousIsValid: boolean;
  private resizeObserver: ResizeObserver;

  constructor(private el: ElementRef, private zone: NgZone) {
    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        this.checkSize(entry.contentRect.width);
      }
    });

    this.zone.runOutsideAngular(() => {
      this.resizeObserver.observe(this.el.nativeElement as HTMLElement);
    });
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  private checkSize(width: number): void {
    const isValid = this.isWidthValid(width);

    if (isValid !== this.previousIsValid) {
      this.previousIsValid = isValid;
      this.zone.run(() => {
        this.resizedEventEmitter(isValid);
      });
    }
  }

  private isWidthValid(width: number): boolean {
    if (this.mq.type === 'max-width') {
      return width <= this.mq.value;
    } else if (this.mq.type === 'min-width') {
      return width >= this.mq.value;
    } else {
      throw new Error(`Unknown mq type: ${this.mq.type}`);
    }
  }

  private resizedEventEmitter(val: boolean): void {
    this.resizedEvent.emit(val);
  }
}

@NgModule({
  exports: [
    ElementResizeDirective,
  ],
  declarations: [
    ElementResizeDirective,
  ],
})
export class ElementResizeModule {
}

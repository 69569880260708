import { ApplicationModel } from '@base/modules/rest/application/model/application.model';
import { StateNameEnum } from '@base/store/state-name.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './reducer';

const state_selector = createFeatureSelector<fromReducer.State>(StateNameEnum.core_application);
export const applications = createSelector(state_selector, (state: fromReducer.State) => state.applications);
export const parameters = createSelector(state_selector, (state: fromReducer.State) => state.parameters);

export const application = (name: string) => createSelector(state_selector, applications,
  (state: fromReducer.State, apps: ApplicationModel[]) => {
    if (!apps) {
      return undefined;
    }
    return apps.find((element: ApplicationModel) => element.name === name);
  }
);

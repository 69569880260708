import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfiguration} from '../../../../app-configuration';
import {ParametersModel} from './model/parameters.model';
import {KeycloakParametersModel} from './model/keycloak-parameters.model';
import {ParameterModel} from './parameter.model';

@Injectable()
export class ParametersRestService {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl;

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  getAllPublic(): Observable<ParametersModel> {
    return this.httpClient.get<ParametersModel>(this.controllerUrl + '/public/parameters/all');
  }

  getAllFull(): Observable<ParameterModel[]> {
    return this.httpClient.get<ParameterModel[]>(this.controllerUrl + '/secured/parameters/all/full');
  }

  getAll(): Observable<ParametersModel> {
    return this.httpClient.get<ParametersModel>(this.controllerUrl + '/secured/parameters/all');
  }

  getKeycloakParameters(): Observable<KeycloakParametersModel> {
    return this.httpClient.get<KeycloakParametersModel>(this.controllerUrl + '/public/parameters/keycloak');
  }

  setKeyValue(key: string, value: any): Observable<void> {
    return this.httpClient.put<void>(this.controllerUrl + '/secured/parameters/' + key, value);
  }

  save(parameter: ParameterModel): Observable<ParameterModel> {
    return this.httpClient.put<ParameterModel>(this.controllerUrl + '/secured/parameters', parameter);
  }
}

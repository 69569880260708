import { Injectable } from '@angular/core';

export interface CanClose {
  canClose(): boolean;
}

@Injectable()
export class CanCloseAppService {
  private listeners = new Set<CanClose>();

  listen(listener: CanClose): void {
    this.listeners.add(listener);
  }

  unlisten(listener: CanClose): void {
    this.listeners.delete(listener);
  }

  canClose(): boolean {
    for (const listener of this.listeners) {
      if (!listener.canClose()) {
        return false;
      }
    }
    return true;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfiguration } from '../../../../app-configuration';
import { PopisExportSettingsModel } from './model/popis-export-settings.model';

@Injectable()
export class PopisExportRestService {
  controllerUrl: string;

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
    this.controllerUrl = this.appConfiguration.serverApiUrl + '/secured/popis';
  }

  export(data: PopisExportSettingsModel): Observable<ArrayBuffer> {
    return this.httpClient.post<ArrayBuffer>(`${this.controllerUrl}/export`, data, {responseType: 'blob' as 'json'});
  }
}

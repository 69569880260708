import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BuiIconModule } from '@shared/modules/bui/bui-icon/bui-icon.module';
import { BuiTooltipIconComponent } from './bui-tooltip-icon.component';

@NgModule({
  declarations: [
    BuiTooltipIconComponent,
  ],
  imports: [
    CommonModule,
    BuiIconModule,
    MatTooltipModule,
  ],
  exports: [
    BuiTooltipIconComponent,
  ],
})
export class BuiTooltipIconModule { }

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

const MQ_BELOW_NARROW = '(max-width: 768px)';

@Component({
  selector: 'app-dialog-template-pager',
  templateUrl: './dialog-template-pager.component.html',
  styleUrls: ['./dialog-template-pager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTemplatePagerComponent {
  isScreenSizeSmall: boolean;
  mqBelowNarrow = MQ_BELOW_NARROW;
  @Input() currentPage: number;
  @Input() totalPages: number;
  @Input() disabled: boolean;
  @Output() previousPageEvent = new EventEmitter();
  @Output() nextPageEvent = new EventEmitter();

  constructor(private mediaMatcher: MediaMatcher) {
    this.isScreenSizeSmall = this.mediaMatcher.matchMedia(this.mqBelowNarrow).matches;
  }
}

import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, NgIf } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SnackBarComponent } from './snack-bar.component';
import { BuiButtonIconModule } from '../../modules/bui/bui-button-icon/bui-button-icon.module';
import { BuiButtonLabelModule } from '../../modules/bui/bui-button-label/bui-button-label.module';
import { ApplicationErrorDetailsDialogModule } from '../dialogs/more-detail-dialog/application-error-details-dialog.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SnackBarComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    NgIf,
    BrowserModule,
    BuiButtonIconModule,
    BuiButtonLabelModule,
    ApplicationErrorDetailsDialogModule,
    TranslateModule,
  ],
  exports: [
    SnackBarComponent,
  ],
})
export class SnackBarModule {}

import { ChangeDetectionStrategy, Component, ContentChild } from '@angular/core';
import { DialogTemplateSearchableHeaderDirective } from './directives/dialog-template-searchable-header.directive';
import { DialogTemplateSearchableSubheaderDirective } from './directives/dialog-template-searchable-subheader.directive';
import { DialogTemplateSearchableBodyDirective } from './directives/dialog-template-searchable-body.directive';
import { DialogTemplateSearchableFooterDirective } from './directives/dialog-template-searchable-footer.directive';

@Component({
  selector: 'app-dialog-template-searchable',
  templateUrl: './dialog-template-searchable.component.html',
  styleUrls: ['./dialog-template-searchable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTemplateSearchableComponent {
  @ContentChild(DialogTemplateSearchableHeaderDirective) headerDirective: DialogTemplateSearchableHeaderDirective;
  @ContentChild(DialogTemplateSearchableSubheaderDirective) subheaderDirective: DialogTemplateSearchableSubheaderDirective;
  @ContentChild(DialogTemplateSearchableBodyDirective) bodyDirective: DialogTemplateSearchableBodyDirective;
  @ContentChild(DialogTemplateSearchableFooterDirective) footerDirective: DialogTemplateSearchableFooterDirective;

  constructTheLayoutModifierClasses(): string {
    const classes: string[] = [];

    if (this.headerDirective) {
      classes.push('header');
    }

    if (this.subheaderDirective) {
      classes.push('subheader');
    }

    if (this.footerDirective) {
      classes.push('footer');
    }

    if (classes.length) {
      return 'c-dialog-template-searchable--with-' + classes.join('-and-');
    }

    return '';
  }
}

import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class DialogTemplateCloseService {

  constructor(private dialogRef: MatDialogRef<any>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserContext } from '@base/services/user-context';

@Injectable({
  providedIn: 'root',
})
export class KeycloakCanActivateGuard extends KeycloakAuthGuard {

  constructor(protected readonly router: Router,
              private userContext: UserContext,
              protected readonly keycloak: KeycloakService) {
    super(router, keycloak);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.userContext.parameters.keycloakEnabled) {
      return Promise.resolve(true);
    }
    return super.canActivate(route, state);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
      return false;
    }
    return true;
  }
}

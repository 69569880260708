import { Component, Input } from '@angular/core';
import {
  DialogTemplateTitleColorEnum,
  DialogTemplateTitleTextAlignEnum
} from '@shared/components/templates/dialog-template/enums/dialog-template-title.enum';

@Component({
  selector: 'app-dialog-template-title',
  templateUrl: './dialog-template-title.component.html',
  styleUrls: ['./dialog-template-title.component.scss'],
})
export class DialogTemplateTitleComponent {
  @Input() pagerVisible: boolean = false;
  @Input() color: DialogTemplateTitleColorEnum = DialogTemplateTitleColorEnum.PRIMARY;
  @Input() textAlign: DialogTemplateTitleTextAlignEnum = DialogTemplateTitleTextAlignEnum.LEFT;
}

import { NgModule } from '@angular/core';
import { CamelCaseToHumanReadableTextPipe } from '@base/pipes/camel-case-to-human-readable-text.pipe';
import { CyrillicPipe } from '@base/pipes/cyrillic.pipe';
import { FormatCssClassNamePipe } from '@base/pipes/format-css-class-name.pipe';
import { FormatDateTimePipe } from '@base/pipes/format-date-time.pipe';
import { FormatDatePipe } from '@base/pipes/format-date.pipe';
import { FormatTimePipe } from '@base/pipes/format-time.pipe';
import { TransformLanguagePipe } from '@base/pipes/transform-language.pipe';
import { TransformListToHumanReadableTextPipe } from '@base/pipes/transform-list-to-human-readable-text.pipe';
import { TransformToHumanReadableTextPipe } from '@base/pipes/transform-to-human-readable-text.pipe';
import { GenerateLookupObjectNamePipe } from '@base/pipes/generate-lookup-object-name.pipe';
import { TruncatePipe } from './truncate.pipe';
import { CustomerTypePipe } from './customer-type.pipe';

const PIPES: any[] = [
  FormatCssClassNamePipe,
  FormatDatePipe,
  FormatTimePipe,
  FormatDateTimePipe,
  CyrillicPipe,
  TransformLanguagePipe,
  TransformToHumanReadableTextPipe,
  TransformListToHumanReadableTextPipe,
  TruncatePipe,
  CamelCaseToHumanReadableTextPipe,
  CustomerTypePipe,
  GenerateLookupObjectNamePipe,
];

@NgModule({
  imports: [],
  exports: [
    ...PIPES,
  ],
  declarations: [
    ...PIPES,
  ],
})
export class PipesModule {
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@base/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularMaterialModule } from '@shared/external-modules/angular-material.module';
import { BuiButtonIconModule } from '@shared/modules/bui/bui-button-icon/bui-button-icon.module';
import { BuiIconModule } from '@shared/modules/bui/bui-icon/bui-icon.module';
import {
  DialogTemplateSearchableComponent
} from './components/dialog-template-searchable/dialog-template-searchable.component';
import {
  DialogTemplateSearchableBodyDirective
} from './components/dialog-template-searchable/directives/dialog-template-searchable-body.directive';
import {
  DialogTemplateSearchableHeaderDirective
} from './components/dialog-template-searchable/directives/dialog-template-searchable-header.directive';
import {
  DialogTemplateSearchableSubheaderDirective
} from './components/dialog-template-searchable/directives/dialog-template-searchable-subheader.directive';
import { DialogTemplateBodyComponent } from './dialog-template-body/dialog-template-body.component';
import { DialogTemplateFooterComponent } from './dialog-template-footer/dialog-template-footer.component';
import {
  DialogTemplateFooterActionsFirstDirective
} from './dialog-template-footer/directives/dialog-template-footer-actions-first.directive';
import {
  DialogTemplateFooterActionsSecondDirective
} from './dialog-template-footer/directives/dialog-template-footer-actions-second.directive';
import {
  DialogTemplateFooterActionsThirdDirective
} from './dialog-template-footer/directives/dialog-template-footer-actions-third.directive';
import {
  DialogTemplateHeaderActionsComponent
} from './dialog-template-header/dialog-template-header-actions/dialog-template-header-actions.component';
import { DialogTemplateHeaderComponent } from './dialog-template-header/dialog-template-header.component';
import {
  DialogTemplateIconComponent
} from './dialog-template-header/dialog-template-icon/dialog-template-icon.component';
import {
  DialogTemplatePagerComponent
} from './dialog-template-header/dialog-template-pager/dialog-template-pager.component';
import {
  DialogTemplateTitleComponent
} from './dialog-template-header/dialog-template-title/dialog-template-title.component';
import { DialogTemplateComponent } from './dialog-template.component';
import { DialogTemplateSearchableFooterDirective } from './components/dialog-template-searchable/directives/dialog-template-searchable-footer.directive';
import { ElementResizeModule } from '../../../directives/element-resize.directive';
import {
  LayoutContentMainModule
} from '../../../modules/layouts/layout-content/layout-content-main/layout-content-main.module';
import { BreakpointObserverModule } from '../../../directives/breakpoint-observer.directive';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    BuiButtonIconModule,
    PipesModule,
    BuiIconModule,
    TranslateModule,
    DragDropModule,
    LayoutContentMainModule,
    ElementResizeModule,
    BreakpointObserverModule,
  ],
  exports: [
    DialogTemplateComponent,
    DialogTemplateHeaderComponent,
    DialogTemplateIconComponent,
    DialogTemplateTitleComponent,
    DialogTemplatePagerComponent,
    DialogTemplateHeaderActionsComponent,
    DialogTemplateBodyComponent,
    DialogTemplateFooterComponent,
    DialogTemplateFooterActionsFirstDirective,
    DialogTemplateFooterActionsSecondDirective,
    DialogTemplateFooterActionsThirdDirective,
    DialogTemplateSearchableComponent,
    DialogTemplateSearchableHeaderDirective,
    DialogTemplateSearchableSubheaderDirective,
    DialogTemplateSearchableBodyDirective,
    DialogTemplateSearchableFooterDirective,
  ],
  declarations: [
    DialogTemplateComponent,
    DialogTemplateHeaderComponent,
    DialogTemplateIconComponent,
    DialogTemplateTitleComponent,
    DialogTemplatePagerComponent,
    DialogTemplateHeaderActionsComponent,
    DialogTemplateBodyComponent,
    DialogTemplateFooterComponent,
    DialogTemplateFooterActionsFirstDirective,
    DialogTemplateFooterActionsSecondDirective,
    DialogTemplateFooterActionsThirdDirective,
    DialogTemplateSearchableComponent,
    DialogTemplateSearchableHeaderDirective,
    DialogTemplateSearchableSubheaderDirective,
    DialogTemplateSearchableBodyDirective,
    DialogTemplateSearchableFooterDirective,
  ],
})
export class DialogTemplateModule {
}
